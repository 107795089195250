export const dayFormat = "DD-MM-YYYY";

export const closedDates = {
  // '10-04-2022': true, // Palmesøndag
  "04-02-2023": true,
  "05-02-2023": true,
  "06-04-2023": true,
  "07-04-2023": true,
  "08-04-2023": true,
  "09-04-2023": true,
  "10-04-2023": true,
  "05-05-2023": true,
  "18-05-2023": true,
  "29-05-2023": true,
  "23-12-2023": true,
  "24-12-2023": true,
  "25-12-2023": true,
  "26-12-2023": true,

  "28-03-2024": true,
  "29-03-2024": true,
  "30-03-2024": true,
  "31-03-2024": true,
  "01-04-2024": true,
  "09-05-2024": true,
  "18-05-2024": true,
  "19-05-2024": true,
  "20-05-2024": true,
};

export const dayFees = {
  "20-04-2019": 200,
};
