import React from 'react';
import styled from 'styled-components/macro';
import {
  Money,
  VareDataModalLink,
  MomsChange,
  PricePrLiter,
  // Price,
} from '../../../../containers/commerce';
import {
  Heading4,
  Paragraph,
  Span,
} from '../../../typography';
import { Row, Col, Div } from '../../../grid';
import { LiterFormat } from '../../../Format';
import { Image, ImagePopup } from '../../../Image';
import BuyButtons from '../BuyButtons';
import FlaskeSelector from '../FlaskeSelector';
import { ScreenSizeDetector } from '../../../../lib/helpers';
import { SalesPrice, AlcoholPercentage, FadKobling } from '../../parts';
import PackImageMultiple from '../PackImageMultiple';
import TooltipModalLink from '../../../TooltipModal/TooltipModalLink';
import RoyalUnibrewOfferText from '../../../RoyalUnibrewOfferText';
import Fjernlager from '../../../Fjernlager';
import VareudsolgtTildato from '../../../UdsolgtTexts/VareudsolgtTildato';
import ExpiredSmallView from './ExpiredSmallView';
import LavLiterpris from '../../../../containers/commerce/LavLiterpris';

const RenderButtons = ({
  amount,
  actions,
  dividableBy,
  inStock,
  inStockText,
  ...otherProps
}) => {
  if (inStock === '-1' && !inStockText) {
    return (
      <Paragraph grey>
        Midlertidigt udsolgt fra producenten
      </Paragraph>
    );
  }
  if (inStock === '100') {
    return (
      <Paragraph grey>
        Udgået fra producenten
      </Paragraph>
    );
  }
  if (inStock === '-1' && inStockText) {
    return (
      <Paragraph grey>
        {inStockText()}
      </Paragraph>
    );
  }
  return (
    <ScreenSizeDetector medium={[[575, true], false]} fullWidth={[[575, false], true]}>
      <BuyButtons amount={amount} actions={actions} dividableBy={dividableBy} {...otherProps} />
    </ScreenSizeDetector>
  );
};
const RoyalUnibrewOffer = styled(Div)`
  border: 2px dotted ${({ theme }) => theme.colors.colorGreen};
  color: ${({ theme }) => theme.colors.colorGreen};
  font-weight: bold;
  padding: 5px 10px;
  position: relative;
  bottom: 5px;
  max-width: 506px;
  line-height: 1.5em;
`;
const RoyalModal = styled(TooltipModalLink)`
  color: ${({ theme }) => theme.colors.colorGreyDark};
  position: relative;
  bottom: 5px;
  line-height: 1.5em;
  border-radius: 10px;
  font-weight: bold;
  padding: 5px 17px 5px 10px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.colorGreyDark};
  }

  ${({ royal2024, theme }) => royal2024 ? `
    font-size: 14px;
    max-width: calc(100% - 40px);
    left: 5px;
    border: 2px solid ${theme.colors.colorYellow};
    &:hover {
      background: ${theme.colors.colorYellow};
      color: ${theme.colors.colorGreyDark};
    }
  ` : `
    max-width: 506px;
    background: ${theme.colors.colorYellow};
  `
  };
`;
const RenderSmallDescription = ({
  categories,
  inStock,
  smallDescription,
  descriptionBestil,
  returPant,
}) => {
  if (descriptionBestil) {
    return (
      <Div marginTop={returPant ? '10px' : '0px'}>
        {descriptionBestil()}
      </Div>
    );
  }
  if (categories.includes('fadoelJuleoel')) {
    if (inStock > '0') {
      return '';
    }
    return (
      <Div marginTop={returPant ? '10px' : '0px'}>
        {smallDescription()}
      </Div>
    );
  }
  return (
    <Div marginTop={returPant ? '10px' : '0px'}>
      {smallDescription()}
    </Div>
  );
};

// eslint-disable-next-line no-unused-vars
export default React.forwardRef((props, ref) => {
  const {
    varenr,
    price,
    beforePrice,
    discount,
    liter,
    name,
    countPrColli,
    amount,
    singleAmount,
    actions,
    caseType,
    singleNoun,
    splittable,
    imageSingle,
    imagePlural,
    highlighted,
    containerMaterial,
    dividableBy,
    smallDescription,
    smag,
    productTypeFadoel,
    alcoholPercentage,
    productTypePalle,
    countPrPalle,
    productTypeReservedel,
    returPant,
    packImages,
    totalCasePrice,
    draughtmaster,
    tooltip,
    modular,
    extraItemRef,
    inStock,
    inStockText,
    royalUnibrewJulekit,
    royalUnibrew,
    heinekenOffer,
    showModularText,
    herslevBryghus,
    norrebro,
    udsolgtTil,
    expired,
    showLiter,
    productTypeIs,
    categories,
    fadKobling,
    productTypeGlass,
    SizeGlass,
    showFlexText,
    lavLiterpris,
    descriptionBestil,
    overWriteTypeAndSize,
  } = props;
  if (expired) {
    return <ExpiredSmallView {...props} />;
  }
  return (
    <Div ref={extraItemRef} white>
      <Row
        className={`vareView_${varenr}`}
        inStock={inStock}
        highlight={highlighted && !(amount > 0 || singleAmount > 0)}
        green={inStock === '0' && (amount > 0 || singleAmount > 0)}
        productTypeFadoel={productTypeFadoel}
        alcoholPercentage={alcoholPercentage}
        productTypePalle={productTypePalle}
        productTypeReservedel={productTypeReservedel}
        returPant={returPant}
        modular={modular}
        margin="0px"
        border="2px solid #D8D8DA"
        borderTop="0px"
        relative
      >
        {!productTypeReservedel && !returPant && (
          <React.Fragment>
            {imageSingle && !imagePlural && (
              <Col xs={3} md={2} textCenter padding="10px 15px">
                <VareDataModalLink varenr={varenr}>
                  <ScreenSizeDetector imageHeight={[[575, '100px'], [767, '70px'], '50px']} maxHeight={[[575, '100px'], [767, '70px'], '50px']}>
                    <Image maxWidth="100%" name={imageSingle} left className="bucketAnimation" />
                  </ScreenSizeDetector>
                </VareDataModalLink>
              </Col>
            )}
            {imagePlural && !imageSingle && (
              <Col xs={3} md={2} textCenter padding="10px 15px">
                <VareDataModalLink varenr={varenr}>
                  <ScreenSizeDetector imageHeight={[[575, '100px'], [767, '70px'], '50px']} maxHeight={[[575, '100px'], [767, '70px'], '50px']}>
                    <Image maxWidth="100%" name={imagePlural} left className="bucketAnimation" />
                  </ScreenSizeDetector>
                </VareDataModalLink>
              </Col>
            )}
            {imagePlural && imageSingle && (
              <Col xs={3} md={2} textCenter padding="10px 15px">
                <Row>
                  <Col xs={12} sm={5} padding="0">
                    <VareDataModalLink varenr={varenr}>
                      <ScreenSizeDetector imageHeight={[[575, '80px'], [767, '70px'], '50px']} maxHeight={[[575, '80px'], [767, '70px'], '50px']}>
                        <Image maxWidth="100%" name={imageSingle} />
                      </ScreenSizeDetector>
                    </VareDataModalLink>
                  </Col>
                  <Col xs={12} sm={7} padding="0">
                    <VareDataModalLink varenr={varenr}>
                      <ScreenSizeDetector imageHeight={[[575, '80px'], [767, '70px'], '50px']} maxHeight={[[575, '80px'], [767, '70px'], '50px']}>
                        <Image maxWidth="100%" name={imagePlural} className="bucketAnimation" />
                      </ScreenSizeDetector>
                    </VareDataModalLink>
                  </Col>
                </Row>
              </Col>
            )}
          </React.Fragment>
        )}
        {productTypeReservedel && (
          <Col xs={3} md={2} textCenter padding="10px 15px">
            {imageSingle && (
              <ScreenSizeDetector imageHeight={[[575, '120px'], [767, '70px'], '50px']}>
                <ImagePopup popupImageHeight="700px" popupMaxWidth="700px" maxWidth="100%" name={imageSingle} left className="bucketAnimation" />
              </ScreenSizeDetector>
            )}
          </Col>
        )}
        <Col xs={returPant ? 12 : 9} md={returPant ? 12 : 10} padding="10px 15px">
          <Row>
            <Col sm={returPant ? 12 : 6} md={returPant ? 6 : 4} xl={returPant ? 6 : 5} padding="0 5px">
              {!productTypeReservedel && !returPant && !draughtmaster && (
                <React.Fragment>
                  <Span block fontWeight="bold" className="bucketAnimation">
                    <VareDataModalLink varenr={varenr}>{name}</VareDataModalLink>
                  </Span>
                </React.Fragment>
              )}
              {(productTypeReservedel || returPant || draughtmaster) && (
                <Span block fontWeight="bold" className="bucketAnimation">
                  {!tooltip && (
                    <React.Fragment>
                      {name}
                    </React.Fragment>
                  )}
                  {tooltip && (
                    <TooltipModalLink blue title={name}>
                      {tooltip()}
                    </TooltipModalLink>
                  )}
                </Span>
              )}
              {smallDescription && (
                <React.Fragment>
                  {categories && (
                    <RenderSmallDescription categories={categories} inStock={inStock} smallDescription={smallDescription} descriptionBestil={descriptionBestil} returPant={returPant} />
                  )}
                  {!categories && (
                    <Div marginTop={returPant ? '10px' : '0px'}>
                      {smallDescription()}
                    </Div>
                  )}
                </React.Fragment>
              )}
              {descriptionBestil && (
                <React.Fragment>
                  {categories && (
                    <RenderSmallDescription categories={categories} inStock={inStock} smallDescription={smallDescription} descriptionBestil={descriptionBestil} returPant={returPant} />
                  )}
                  {!categories && (
                    <Div marginTop={returPant ? '10px' : '0px'}>
                      {descriptionBestil()}
                    </Div>
                  )}
                </React.Fragment>
              )}
              {lavLiterpris && (
                <Div marginTop={returPant ? '10px' : '0px'}>
                  <LavLiterpris lavLiterpris={lavLiterpris} />
                </Div>
              )}
              {udsolgtTil && (
                <Div marginTop="0px"><VareudsolgtTildato udsolgtTil={udsolgtTil} /></Div>
              )}
              {smag && (
                <Div marginTop={returPant ? '10px' : '0px'}>
                  <Span block fontSize="13px" paddingTop="5px">{smag}</Span>
                </Div>
              )}
              {inStock > 0 && inStock !== '100' && (
                <Div marginTop={returPant ? '10px' : '0px'}>
                  <Span block fontSize="13px" red paddingTop="5px">
                    {inStock} tilbage på lager!
                  </Span>
                </Div>
              )}
              {inStock <= -2 && (
                <Div marginTop={returPant ? '10px' : '0px'}>
                  <Span block fontSize="13px" blue paddingTop="5px">
                    <Fjernlager inStock={inStock} />
                  </Span>
                </Div>
              )}
              {showModularText && (
                <React.Fragment>
                  <Paragraph paddingTop="5px" grey={(inStock === '-1' || inStock === '100')} fontSize="12px">
                    Modular 20 fustage. Kræver eget Modular 20 anlæg. Vi udlejer ikke udstyret til disse fustager. Lagerføres ikke, tages kun hjem på bestilling og mod forud betaling, ingen returret.
                  </Paragraph>
                </React.Fragment>
              )}
              {showFlexText && (
                <React.Fragment>
                  <Paragraph paddingTop="5px" grey={(inStock === '-1' || inStock === '100')} fontSize="12px">
                    Flex 20 fustage. Kræver eget Flex 20 anlæg. Vi udlejer ikke udstyret til disse fustager. Lagerføres ikke, tages kun hjem på bestilling og mod forud betaling, ingen returret.
                  </Paragraph>
                </React.Fragment>
              )}
              {discount && discount > 0 && (
                <SalesPrice beforePrice={beforePrice} discount={discount} nembestilling />
              )}
              {!productTypeFadoel && !productTypePalle && !productTypeReservedel && !returPant && (
                <React.Fragment>
                  <ScreenSizeDetector marginTop={[[479, '5px'], '10px']}>
                    <Span block>
                      {showLiter
                        ? (
                          <React.Fragment>
                            <LiterFormat liter={liter} divider={countPrColli} />
                            {containerMaterial && (<React.Fragment>&nbsp;{containerMaterial.toLowerCase()}</React.Fragment>)}
                            {singleNoun && (<React.Fragment>&nbsp;{singleNoun.toLowerCase()}</React.Fragment>)}
                          </React.Fragment>
                        )
                        : (
                          <React.Fragment>
                            {!productTypeIs && !productTypeGlass && (
                              <React.Fragment>
                                <LiterFormat liter={liter} cl divider={countPrColli} />
                                {containerMaterial && (<React.Fragment>&nbsp;{containerMaterial.toLowerCase()}</React.Fragment>)}
                                {singleNoun && (<React.Fragment>&nbsp;{singleNoun.toLowerCase()}</React.Fragment>)}
                              </React.Fragment>
                            )}
                            {productTypeGlass && (
                              <React.Fragment>
                                {SizeGlass >= 1000 && (
                                  <React.Fragment>
                                    {SizeGlass / 1000} liter
                                  </React.Fragment>
                                )}
                                {SizeGlass < 1000 && SizeGlass > 0 && (
                                  <React.Fragment>
                                    {SizeGlass} cl
                                  </React.Fragment>
                                )}
                                {containerMaterial && (<React.Fragment>&nbsp;{containerMaterial.toLowerCase()}</React.Fragment>)}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )
                      }
                    </Span>
                  </ScreenSizeDetector>
                </React.Fragment>
              )}
              {productTypeFadoel && (
                <React.Fragment>
                  <ScreenSizeDetector block={[[575, false], true]} marginTop={[[479, '5px'], '10px']}>
                    <Span block>
                      <AlcoholPercentage value={alcoholPercentage} /> - <FadKobling noPrefix value={fadKobling} />-kobling
                    </Span>
                  </ScreenSizeDetector>
                </React.Fragment>
              )}
              {productTypePalle && (
                <React.Fragment>
                  <ScreenSizeDetector marginTop={[[479, '5px'], '10px']}>
                    <Span block>
                      {countPrPalle} x {countPrColli} x <LiterFormat liter={liter} cl divider={countPrColli} />
                      {containerMaterial && (<React.Fragment>&nbsp;{containerMaterial.toLowerCase()}</React.Fragment>)}
                      {singleNoun && (<React.Fragment>&nbsp;{singleNoun.toLowerCase()}</React.Fragment>)}
                    </Span>
                  </ScreenSizeDetector>
                </React.Fragment>
              )}
            </Col>
            {!returPant && (
            <ScreenSizeDetector marginTop={[[575, '10px'], '0px']} padding={[[575, '0 5px'], '0px']}>
              <Col sm={6} md={productTypePalle ? 4 : 3}>
                {!productTypeFadoel && !productTypePalle && !productTypeReservedel && !returPant && !productTypeIs && (
                  <Span block fontWeight="bold" className="TypeAndSize">
                    {!overWriteTypeAndSize && (
                      <React.Fragment>
                        {caseType !== ' ' && (<React.Fragment>{caseType} á </React.Fragment>)}{countPrColli} stk.
                      </React.Fragment>
                    )}
                    {overWriteTypeAndSize && (
                      <React.Fragment>
                        {overWriteTypeAndSize}
                      </React.Fragment>
                    )}
                  </Span>
                )}
                {productTypePalle && (
                  <React.Fragment>
                    <Span block fontWeight="bold">
                      EUR-palle á {countPrColli * countPrPalle} flasker
                    </Span>
                  </React.Fragment>
                )}
                {productTypeFadoel && (
                  <React.Fragment>
                    <Span block fontWeight="bold">
                      <LiterFormat liter={liter} noSuffix /> liters {caseType}
                    </Span>
                    <ScreenSizeDetector marginTop={[[479, '5px'], '10px']}>
                      <Span>
                        Literpris:&nbsp;
                        <ScreenSizeDetector noSuffix={[[575, true], false]}>
                          <PricePrLiter varenr={varenr} />
                        </ScreenSizeDetector>
                      </Span>
                    </ScreenSizeDetector>
                  </React.Fragment>
                )}
                {productTypePalle && (
                  <React.Fragment>
                    <ScreenSizeDetector marginTop={[[479, '5px'], '10px']} block={[[575, false], true]}>
                      <Span block>
                        Pris pr. stk: <Money money={price} divider={countPrColli} multiplier={1 / countPrColli} /> <MomsChange />
                      </Span>
                    </ScreenSizeDetector>
                    <Span block>
                      Pris pr. kolli: <Money money={price} /> <MomsChange />
                    </Span>
                  </React.Fragment>
                )}
                {productTypeReservedel && (
                  <Span block>
                    <Money money={price} momsIndication />
                  </Span>
                )}
                {!productTypeFadoel && !productTypePalle && !productTypeReservedel && (
                  <React.Fragment>
                    <ScreenSizeDetector marginTop={[[479, '5px'], '10px']} block={[[575, false], true]}>
                      <Span>
                        <ScreenSizeDetector momsIndication={[[575, false], true]}>
                          <Money money={price} momsIndication />
                        </ScreenSizeDetector>
                        <ScreenSizeDetector hide={[[575, false], true]} marginLeft={[[575, '3px'], '0px']}>
                          <Span><MomsChange /></Span>
                        </ScreenSizeDetector>
                      </Span>
                    </ScreenSizeDetector>
                  </React.Fragment>
                )}
              </Col>
            </ScreenSizeDetector>
            )}
            <ScreenSizeDetector marginTop={[[575, '10px'], [767, '15px'], '0px']}>
              <Col sm={6} md={productTypePalle ? 1 : (returPant ? 3 : 2)} padding="0 5px">
                {splittable && splittable > 1 && inStock !== '-1' && (
                <Span block marginTop="5px">
                  <FlaskeSelector
                    singleAmount={singleAmount}
                    actions={actions}
                    countPrColli={countPrColli}
                  />
                </Span>
                )}
                {productTypeFadoel && (
                  <React.Fragment>
                    <ScreenSizeDetector block={[[575, false], true]}>
                      <Span fontWeight="bold">
                        <Money money={price} />
                      </Span>
                    </ScreenSizeDetector>
                    <ScreenSizeDetector marginTop={[[575, '0px'], '5px']} block={[[575, false], true]} marginLeft={[[575, '3px'], '0px']}>
                      <Span>
                        <MomsChange />
                      </Span>
                    </ScreenSizeDetector>
                  </React.Fragment>
                )}
                {returPant && (
                  <Money money={totalCasePrice} momsIndication multiplier={-1} />
                )}
              </Col>
            </ScreenSizeDetector>
            <ScreenSizeDetector marginTop={[[575, '10px'], [767, '15px'], '0px']}>
              <Col sm={6} md={3} xl={inStockText && inStock === '-1' ? 2 : (returPant ? 3 : 2)} padding="0 5px">
                <RenderButtons amount={amount} actions={actions} inStockText={inStockText} inStock={inStock} dividableBy={dividableBy} />
              </Col>
            </ScreenSizeDetector>
          </Row>
        </Col>
        {returPant && packImages && packImages.length > 0 && (
          <Col xs={12} padding="5px">
            <PackImageMultiple packImages={packImages} />
          </Col>
        )}
        {royalUnibrew && (
          <React.Fragment>
            <RoyalModal royal2024 title={<React.Fragment>24 gratis dåseøl ved køb af mindst 3 fustager Nørrebro, Royal IPA eller Royal Blanche</React.Fragment>}>
              <Row>
                <Col xs={12} md={4} textCenter>
                  <Image name="produkter-oelvand.royal-blanch-daase.png" maxHeight="200px" />
                </Col>
                <Col xs={12} md={8}>
                  <RoyalUnibrewOfferText />
                </Col>
              </Row>
            </RoyalModal>
          </React.Fragment>
        )}
        {heinekenOffer && (
          <React.Fragment>
            <RoyalModal royal2024 title={<React.Fragment>12 gratis glas ved køb af mindst 3 fustager Heineken</React.Fragment>}>
              <Row>
                <Col xs={12} textCenter>
                  <Image name="diverse.heineken-tilbud-banner.png" maxHeight="200px" />
                </Col>
                <Col xs={12}>
                  <Paragraph bold>
                    12 gratis glas ved køb af mindst 3 fustager Heineken
                  </Paragraph>
                  <Paragraph>
                    Når du køber mindst 3 fustager Heineken kan du få 12 glas gratis med
                    din ordre (Max 12 gratis glas pr. ordre).
                  </Paragraph>
                  <Paragraph bold red>
                    Sendes direkte til din adresse fra Royal Unibrew.
                  </Paragraph>
                </Col>
              </Row>
            </RoyalModal>
          </React.Fragment>
        )}
        {herslevBryghus && (
          <Col xs={12} md={10} offset={{ xs: 0, md: 2 }} padding="5px">
            <RoyalModal title="Gratis smagskasse ved køb af 2 fustager">
              <Row>
                <Col xs={12} md={4} textCenter>
                  <Image name="produkter-oelvand.herslev-gaveaeske.png" maxHeight="200px" />
                </Col>
                <Col xs={12} md={8} alignCenter>
                  <Heading4>Gratis smagskasse ved køb af 2 fustager</Heading4>
                  <Paragraph>
                    Når du køber mindst 2 fustager fra Herslev Bryghus kan du få en gratis smagskasse med din ordre.
                  </Paragraph>
                  <Paragraph>
                    Smagskassen indeholder Herslev Øko Juleøl, Herslev Øko Stjernebryg og Herslev Øko Snefri (0,5%).
                  </Paragraph>
                  <Paragraph>Max 1 gratis smagskasse pr. ordre.</Paragraph>
                  <Paragraph>Pant bliver lagt til i kurven.</Paragraph>
                </Col>
              </Row>
            </RoyalModal>
          </Col>
        )}
        {norrebro && (
          <Col xs={12} md={10} offset={{ xs: 0, md: 2 }} padding="5px">
            <RoyalModal title="Få 12 gratis glas ved køb af 2 fustager">
              <Row>
                <Col xs={12} md={4} textCenter>
                  <Image name="produkter-fadoel.noerrebro-glas-tilbud.png" maxHeight="200px" />
                </Col>
                <Col xs={12} md={8}>
                  <Heading4>Få 12 gratis glas ved køb af 2 fustager</Heading4>
                  <Paragraph>
                    Når du køber mindst 2 fustager fra Nørrebro Bryghus får du 12 gratis glas fra bryggeriet med din ordre.
                  </Paragraph>
                  <Paragraph>Max 1 omgang gratis glas pr. ordre.</Paragraph>
                  <Paragraph>Kampagnen gælder i september og oktober 2023.</Paragraph>
                </Col>
              </Row>
            </RoyalModal>
          </Col>
        )}
        {royalUnibrewJulekit && inStock >= 0 && (
        <RoyalUnibrewOffer>
          Få ét gratis julekit bestående af 2 x nissehuer, 2 x knappehulsblinkere og 1 x guirlande med din ordre ved køb af disse fustager. (Max 1 julekit pr. ordre)
        </RoyalUnibrewOffer>
        )}
      </Row>
    </Div>
  );
});
