import React from "react";
import {
  Bold,
  Heading3,
  Paragraph,
  Span,
  LinkStyled,
} from "../../../components/typography";
import { Row, Col } from "../../../components/grid";
import Datoer from "../../../components/Datoer";
import { RecieptPackEntryView } from "../../../components/commerce/Bucket";
import { SmallView } from "../../../components/commerce/Item/list-views";
import { Price } from "../../../containers/commerce";
import { Table, Td } from "../../../components/tables";

export default [
  /* Udgåede varer */
  {
    varenr: 1000000,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>
        Udgået fra producenten februar 2020. Wiibroe laves ikke længere på
        fustager - bryggeriet har stoppet produktionen, sælges nu kun på flasker
        og dåser.
      </React.Fragment>
    ),
    expiredSize: "25 l",
    name: "Wiibroe Pilsner",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl wibro wibroe dansk danske danmark",
    imageSingle: "produkter-fadoel.wiibroe-pilsner.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    // fadoelsType: 'Pilsner',
    land: "Danmark",
    landKort: "DK",
    productPageDescription: () => (
      <React.Fragment>
        <Heading3>Flot gylden farve og et fint hvidt skum</Heading3>
        <Paragraph>
          Wiibroe Pilsner er en god gammel dansk pilsner (lager) med en svag
          duft og humle. Den har en let syrlig smag og kort eftersmag. Wiibroe
          Pilsner har en lys gylden farve og en alkoholstyrke 4,6 %. Wiibroe
          Pilsner blev solgt første gang helt tilbage i 1896 og var en succes
          fra start.
        </Paragraph>
        <Paragraph>
          Wiibroe Pilsner bliver brygget på gode råvarer inden for byg, malt og
          humle. Der er tale om en god dansk pilsner med en svag duft og let
          syrlig smag. Den meget lette smag gør det til en øl, som kan drikkes
          af de fleste. Den har desuden en flot gylden farve og et fint hvidt
          skum, hvilket gør det til en øl, der ser indbydende ud i et glas.
          Wiibroe Pilsner bør serveres ved 3-5 grader og er perfekt til
          frokosten og lette retter som fx fisk og skaldyr.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>Wiibroes Bryggeri blev grundlagt i 1840</Heading3>
            <Paragraph>
              Wiibroe eller Wiibroes Bryggeri er et nordsjællandsk bryggeri, der
              blev grundlagt i 1840 af Carl Wiibroe. Wiibroe var det første
              ikke-københavnske bryggeri, som solgte øl på flaske i Danmark.
              Carlsberg Danmark har ejet bryggeriet siden 1964.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    gas: "CO2",
    pressure: "2,5-3 bar",
    popupDescription: () => (
      <React.Fragment>
        God gammel dansk pilsner med en svag duft og humle. Lys gylden farve med
        en let syrlig smag og kort eftersmag.
        <br />
        <br />
        Solgt første gang i 1896.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000001,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten februar 2020.</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "9690",
    name: "Vitamin Well Prepare",
    keywords: "kildevand",
    imageSingle: "produkter-oelvand.9690-flaske.png",
    imagePlural: "produkter-oelvand.9690-kasse.png",
    // smag: 'Passionsfrugt',
  },
  {
    varenr: 1000003,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2018.</React.Fragment>
    ),
    expiredSize: "30 l",
    oldVareNr: "18645",
    name: "Hornbeer Øko Beware Pale Ale",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark økologisk keykeg pose india indian pale ale ipa",
    imageSingle: "produkter-fadoel.hornbeer-beware.png",
    imagePlural: "produkter-fadoel.hornbeer-fustage-1.png",
    // fadoelsType: 'Lys Ale',
    land: "Danmark",
    landKort: "DK",
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Som i keykegs indeholder Hornbeer plastik engangsfustager en pose
          hvori øllet er. Kulsyre eller trykluft kommer altså ikke i direkte
          kontakt med øllet.
        </Span>
      </React.Fragment>
    ),
    productPageDescription: () => (
      <React.Fragment>
        <Heading3>Dansk produceret, økologisk fadøl</Heading3>
        <Paragraph>
          Beware fra Hornbeer bryggeri er en tør og frisk økologisk Pale Ale.
          Denne fadøl er brygget &quot;tørhumlet&quot; - hvilket vil sige at der
          under brygningen bliver lagt en sæk humle i gæringstanken. Dette giver
          Beware fadøl en frisk duft af humle.
        </Paragraph>
        <Paragraph>
          Smagen har nuancer af malt og frisk humle. Beware fadøl går godt til
          frokost, grill og hvis du generelt bare er tørstig efter en forrygende
          fadøl.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000004,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten oktober 2019.</React.Fragment>
    ),
    expiredSize: "25 cl",
    oldVareNr: "5360",
    name: "Coca-Cola Light",
    keywords:
      "sukkerfri sukker fri 30 30stk stk flasker 25cl 25 cl 30x25cl 30x25 coca cola zero light sodavand",
    imageSingle: "produkter-oelvand.coca-cola-light-25cl-1.png",
    imagePlural: "produkter-oelvand.5360-kasse.png",
  },
  {
    varenr: 1000005,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten februar 2019.</React.Fragment>
    ),
    expiredSize: "50 cl",
    oldVareNr: "5382",
    name: "Coca-Cola Life",
    keywords:
      "sukkerfri sukker fri 24 24stk stk flasker 50cl 50 cl 24x50cl 24x50 coca cola zero light sodavand",
    imageSingle: "produkter-oelvand.coca-cola-life-50cl.png",
    imagePlural: "produkter-oelvand.5382-kasse.png",
  },
  {
    varenr: 1000006,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten marts 2019.</React.Fragment>
    ),
    expiredSize: "33 cl",
    oldVareNr: "5383",
    name: "Coca-Cola Life Dåse",
    imageSingle: "produkter-oelvand.coca-cola-life-33cl-daase-1.png",
    imagePlural: "produkter-oelvand.coca-cola-life-33cl-daase-ramme-1.png",
  },
  {
    varenr: 1000007,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>
        Udgået fra producenten på stålfustage marts 2018. Findes som Modular20,
        kan tages hjem på forespørgsel.
      </React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "8595",
    name: "Brooklyn East India Pale Ale",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl udenlandske usa brocklyn",
    imageSingle: "produkter-fadoel.brooklyn-east-india-pale-ale.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    // fadoelsType: 'Lys Ale',
    land: "USA",
    landKort: "USA",
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          Brooklyn East India Pale Ale skal ses som en amerikansk fortolkning af
          den oprindelige britiske India Pale Ale. Øllen blev i sin tid brygget
          med henblik på at forsyne de britiske kolonister og soldater, der var
          udstationeret i Indien. Øllen skulle derfor have en lang holdbarhed,
          og India Pale Ale fik derfor tilsat ekstra malt og humle.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <Heading3>Smag og farve</Heading3>
            <Paragraph>
              Brooklyn East India Pale er vellidt blandt mange pga. den
              humlearomatiske profil, der gør netop denne fadøl til noget helt
              særligt. Øllen har en stor fylde samt en smule sødme i smagen, der
              hurtigt overtages af en tør og let snerpende efterbitterhed. Du
              vil mod afslutningen også kunne fornemme en varmende
              alkoholfornemmelse.
            </Paragraph>
            <Paragraph>
              Øllen er brygget på en blanding af britiske og fremstår klar lys
              ravfarvet. Øllet har en veludviklet råhvid skumkrone, der dog er
              rimeligt hurtigt aftagende, men som efterlader et fint slør i
              glasset.
            </Paragraph>
          </Col>
          <Col xs={12} md={6}>
            <Heading3>Duften</Heading3>
            <Paragraph>
              Brooklyn East India Pale dufter udpræget af hyld, citrus og en
              frugtighed som er typisk for de tilsatte amerikanske humlesorter.
            </Paragraph>
            <Paragraph>
              <Bold>Mad:</Bold> Øllen gør godt sammen med dampet fisk såsom
              lange, mulle og hvilling – men vil også passe fint til sursøde
              retter fra det asiatiske køkken.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    productTypeFadoel: true,
  },
  {
    varenr: 1000008,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>
        Carlsberg er stoppet med at producere Carls Special på fustage og denne
        fadøl kan derfor ikke længere bestilles.
      </React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "2655",
    name: "Carls Special",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark",
    imageSingle: "produkter-fadoel.carls-special.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    // fadoelsType: 'Classic ',
    land: "Danmark",
    landKort: "DK",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          Carls Special er en rund og fyldig, undergæret bayersk øltype med en
          mørkgylden farve. Carls Special er en all-malt øl. Ved brygningen er
          der kun brugt malt og humle og ikke råfrugt (majs). Carls Special er
          velegnet til fx grillet og ovnstegt svine- og kalvekød og ovnstegte
          grøntsager.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <Heading3>Carls Special er en øl med lidt sødlig karakter</Heading3>
            <Paragraph>
              Carls Special er en mørkgylden lagerøl, der blev udvalgt som
              Carlsbergs 150-års jubilæumsøl. Den er inspireret af klassiske
              bayerske og østrigske undergærede øltyper fra midten af det 19.
              århundrede. Der er brugt müncher malt, hvilket giver aromaen et
              maltet præg. Karamelmalten giver en karamelagtig og ristet
              karakter.
            </Paragraph>
            <Paragraph>
              Smagen er derfor noget sødere end Carlsberg Pilsner. Det skyldes,
              at en mindre del af maltsukkeret er omdannet til alkohol.
              Alkoholprocenten er også lidt lavere. Den større rest af
              maltsukker giver Carls Special mere krop end Carlsberg Pilsner.
              Der er eftersmag af malten og humlebitterheden.
            </Paragraph>
          </Col>
          <Col xs={12} md={6}>
            <Heading3>TIP: Nyd den ved 8-12 °C til fx grillmad</Heading3>
            <Paragraph>
              For at få det fulde udbytte af Carls Special bør den ikke serveres
              for kold. Grillet og ovnstegt svine- og kalvekød og ovnstegte
              grøntsager passer godt til Carls Special.
            </Paragraph>
            <Paragraph>
              Øllen kan også serves til fx forskellige supper og stegt kylling.
              Den bør nydes ved 8-12 °C.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000009,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment maj 2019</React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "12535",
    name: "Willemoes IPA",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl letøl alkoholfri frokostøl alkoholdfri alkolholfri alkoholfrit let light ølight øllight light øl dansk danske danmark 19l 19 liter l",
    imageSingle: "produkter-fadoel.willemoes-session-ipa.png",
    imagePlural: "produkter-fadoel.willemoes-session-ipa-fustage.png",
    // fadoelsType: 'Indian Pale Ale',
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000010,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>
        Udgået fra den danske importør marts 2018. Vi foreslår Murphy&apos;s Red
        Ale.
      </React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "2765",
    name: "Kilkenny",
    keywords: "fustage fustager fustace fus fad fadølsfustage fadøl",
    imageSingle: "produkter-fadoel.kilkenny.png",
    imagePlural: "produkter-fadoel.guinness-fustage.png",
    // fadoelsType: 'Rød Ale',
    land: "Irland",
    landKort: "IE",
    productPageDescription: () => (
      <React.Fragment>
        <Heading3>Fin smag af humle, malt og lidt frugt</Heading3>
        <Paragraph>
          Kilkenny er en overgæret irsk rød ale med en kraftig let cremet skum,
          der arbejder sig hurtigt op til overfladen og sætter sig i toppen. Den
          har en duft af karamel, malt og hasselnødder og aromahumle. Smagen er
          behagelig sød af riset malt. Den har en kort og let bitter eftersmag.
          Alkoholstyrken er 4,3%.
        </Paragraph>
        <Paragraph>
          Kilkenny (Kilkenny Irish Cream Ale) er nitrogeneret irsk fløde ale,
          der bliver brygget på en blanding af vand, maltet byg, ristet maltet
          byg, humle og gær. Kilkenny smager fint af humle, malt og lidt frugt,
          og der er en fin balance mellem den lette sødme og humlens bitterhed.
          Den har et flot tæt skum og en smuk rødlig ravfarve.
        </Paragraph>
        <Paragraph>
          I fadølsudgaven anvendes kvælstof som drivgas. Det giver et meget tæt
          og flødeskumsagtigt skum. Den bør skænkes i et stort og gerne højt
          glas. Køb Kilkenny her.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Paragraph>
              Kilkenny egner sig godt til stuvede retter med kål, spinat og
              rodfrugter til fx lammekød, svinekød og laks. Den bør ikke
              serveres for kold, men derimod omkring 10 ºC.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000011,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>
        Carlsberg er stoppet med at importere Spaten München, så det er i
        øjeblikket ikke muligt at få denne fadøl i Danmark.
      </React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "8865",
    name: "Spaten München",
    keywords: "fustage fustager fustace fus fad fadølsfustage fadøl",
    imageSingle: "produkter-fadoel.spaten-munchen.png",
    // fadoelsType: 'Pilsner',
    land: "Tyskland",
    landKort: "DE",
    productPageDescription: () => (
      <React.Fragment>
        <Heading3>”Det daglige brød” i München</Heading3>
        <Paragraph>
          Spaten München eller bare Spaten har en flot gylden i farve. Den har
          en kraftig hvid og cremet skumkrone, som sidder længe i glasset.
          Spaten München dufter friskt og sødligt af korn og lyst malt med klare
          aromatiske toner af bayerske humlesorter. Det er en af de få øl, der
          må sælges til den verdensberømte Oktoberfest i München. Det er en øl,
          der kan bruges til det meste og drikkes af de fleste.
        </Paragraph>
        <Paragraph>
          Spaten er en af Münchens store ølmærker. Dens historie går tilbage til
          1397, hvor et lille bryggeri i det centrale München begyndte at brygge
          øl. Spaten er en undergæret hvedeøl. Den nye øltype i pilsner-stil
          blev hurtig populær og betegnes som en del af det ”daglige brød” for
          mange borgere i München den dag i dag. Det er også en vellykket øl med
          en blød, fyldig og smøragtig smag. Når den gyldne pilsner skænkes i
          glasset dannes en kraftig hvid, cremet skumkrone, der efterlader et
          yderst vedholdende slør i glasset.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>Tip: Servér den kold til hverdagsmad</Heading3>
            <Paragraph>
              Spaten München er en hverdagsøl, der kan passe til mange retter
              lige fra pølser, skinke til stegt kylling. Servér den
              køleskabskold.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000012,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra importøren marts 2018.</React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "8835",
    name: "Staropramen Premium",
    keywords: "fustage fustager fustace fus fad fadølsfustage fadøl tjekkiet",
    imageSingle: "produkter-fadoel.staropramen-premium.png",
    imagePlural: "produkter-fadoel.franziskaner-fustage.png",
    // fadoelsType: 'Pilsner',
    land: "Tjekkiet",
    landKort: "CZ",
    productPageDescription: () => (
      <React.Fragment>
        <Heading3>
          Brygges i Prag og er den tjekkiske hovedstads foretrukne øl
        </Heading3>
        <Paragraph>
          Staropramen Premium er en undergæret klar og lys bøhmisk lagerøl. Den
          har en gylden farve med en frisk og fyldig smag. Den dufter friskt og
          rent med maltede noter af hø og nybagt brød. Den føles rund på tungen
          med en behagelig bitter eftersmag. Den har en kortlivet hvid
          skumkrone. Kulsyreudviklingen ses næsten ikke i øllet.
        </Paragraph>
        <Paragraph>
          Staropramen Premium brygges i Prag og regnes for den tjekkiske
          hovedstads foretrukne øl. Bryggeriet Staropramen blev grundlagt
          allerede i 1869 og ligger den dag i dag på samme sted sydvest for den
          gamle bydel.
        </Paragraph>
        <Paragraph>
          Staropramen har en klar lys gylden farve med en indbydende hvid
          skumkrone. Staropramen dufter tydeligt af Saazer humle. Den er
          velafbalanceret og fyldig. Det er ægte tjekkisk øl af højeste
          kvalitet.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>
              TIP: Servér Staropramen køleskabskold til tysk og ungarsk
              inspireret mad
            </Heading3>
            <Paragraph>
              Staropramen Premium er en hverdagsøl, der kan passe til mange
              retter lige fra pølser, skinke til stegt kylling. Servér den
              køleskabskold.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000013,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2019.</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "9693",
    name: "Vitamin Well Prepare Zero",
    imageSingle: "produkter-oelvand.vitamin-well-prepare-zero.png",
    imagePlural: "produkter-oelvand.vitamin-well-prepare-zero-ramme.png",
    smag: "Passionsfrugt",
    dontIndex: true,
  },
  {
    varenr: 1000014,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2019.</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "9643",
    name: "Vitamin Well Reload Zero",
    imageSingle: "produkter-oelvand.vitamin-well-reload-zero.png",
    imagePlural: "produkter-oelvand.vitamin-well-reload-zero-ramme.png",
    smag: "Citron / Lime",
    dontIndex: true,
  },
  {
    varenr: 1000015,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2019.</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "9603",
    name: "Vitamin Well Hydrate Zero",
    imageSingle: "produkter-oelvand.vitamin-well-hydrate-zero.png",
    imagePlural: "produkter-oelvand.vitamin-well-hydrate-zero-ramme.png",
    smag: "Rabarber / Jordbær",
    inStockText: () => "Udgået fra vores sortiment 20. marts 2019",
    dontIndex: true,
  },
  {
    varenr: 1000016,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra bryggeriet september 2019.</React.Fragment>
    ),
    expiredSize: "24x33cl",
    oldVareNr: "8390",
    imageSingle: "produkter-oelvand.jacobsen-saaz-blonde-flaske.png",
    imagePlural: "produkter-oelvand.8390-kasse.png",
    name: "Jacobsen Saaz Blonde",
    dontIndex: true,
  },
  {
    varenr: 1000017,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2019.</React.Fragment>
    ),
    expiredSize: "24x33cl",
    oldVareNr: "8890",
    imageSingle: "produkter-oelvand.jacobsen-extra-pils-flaske.png",
    imagePlural: "produkter-oelvand.8890-kasse.png",
    name: "Jacobsen Extra Pils",
    dontIndex: true,
  },
  {
    varenr: 1000018,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra bryggeriet marts 2019.</React.Fragment>
    ),
    expiredSize: "24x33cl",
    oldVareNr: "8330",
    name: "Jacobsen Christmas Ale",
    imageSingle: "produkter-oelvand.8330-flaske.png",
    dontIndex: true,
  },
  {
    varenr: 1000019,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juni 2019</React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "8805",
    name: "Brewmaster IPA",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark india pale ale lys",
    imageSingle: "produkter-oelvand.8805-flaske.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'India Pale Ale',
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    dontIndex: true,
  },
  {
    varenr: 1000021,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juli 2019</React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "40875",
    name: "Kissmeyer Icons Strange Fruit Tripel",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark dark ipa",
    imageSingle: "produkter-fadoel.40875-flaske.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'Mørk Ale',
    gas: "CO2",
    pressure: "2,5-3 bar",
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="10px">
          Craftbeer | Mørk Ale
        </Span>
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    dontIndex: true,
  },
  {
    varenr: 1000022,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten marts 2020</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "8302",
    name: "Skovlyst Øko Enebærbryg",
    imageSingle: "produkter-oelvand.skovlyst-oeko-enebaerbryg.png",
  },
  {
    varenr: 1000023,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten marts 2020</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "8292",
    name: "Skovlyst Øko Hyldebryg",
    imageSingle: "produkter-oelvand.skovlyst-oeko-hyldebryg.png",
  },
  {
    varenr: 1000024,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2020</React.Fragment>
    ),
    expiredSize: "12x25cl",
    oldVareNr: "6610",
    name: "MySmoothie Mango",
    imageSingle: "produkter-oelvand.mysmoothie-mango.png",
    imagePlural: "produkter-oelvand.6610-kasse.png",
    headlineXtra:
      "Med mango, æble, banan, druesaft, appelsin, og passionsfrugt.",
    description: () =>
      "Ingen pant. Indhold: 25% mango puré, 20% æblejuice*, 20% bananpuré, 16% druesaft*, 15% appelsinjuice*, 4% passionsfrugt juice*. *fra koncentrat.",
  },
  {
    varenr: 1000025,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2020</React.Fragment>
    ),
    expiredSize: "12x25cl",
    oldVareNr: "6690",
    name: "MySmoothie Ananas",
    imageSingle: "produkter-oelvand.mysmoothie-ananas.png",
    imagePlural: "produkter-oelvand.6690-kasse.png",
    headlineXtra: "Med kokosmælk, ananas, citron, banan og kostfibre.",
    description: () =>
      "Ingen pant. Webmasters foretrukne. Indhold: 69% ananasjuice, 29% bananpuré*, 10% kokosmælk* 2% citronjuice*, 0,9% kostfibre.",
  },
  {
    varenr: 1000026,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2020</React.Fragment>
    ),
    expiredSize: "12x25cl",
    oldVareNr: "6620",
    name: "MySmoothie Hindbær",
    imageSingle: "produkter-oelvand.mysmoothie-hindbaer.png",
    imagePlural: "produkter-oelvand.6620-kasse.png",
    headlineXtra: "Med hindbær, æble, druesaft, banan og hyldebær.",
    description: () =>
      "Ingen pant. Indhold: 40% æblejuice*, 26,5% hindbærpuré, 17% druesaft*, 14% bananpuré, 2,5% hyldebær juice*. *fra koncentrat.",
  },
  {
    varenr: 1000027,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2020</React.Fragment>
    ),
    expiredSize: "12x25cl",
    oldVareNr: "6650",
    name: "MySmoothie Blåbær",
    imageSingle: "produkter-oelvand.mysmoothie-blaabaer.png",
    imagePlural: "produkter-oelvand.6650-kasse.png",
    headlineXtra: "Med blåbær, æble, banan og druesaft.",
    description: () =>
      "Ingen pant. Roskilde Roklubs favorit. Indhold: 47% æblejuice*, 18% blåbær, 16,5% bananpuré, 10% druesaft*, 8,5% hindbærpuré. *fra koncentrat.",
  },
  {
    varenr: 1000028,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2020</React.Fragment>
    ),
    expiredSize: "12x25cl",
    oldVareNr: "6660",
    name: "MySmoothie Jordbær",
    imageSingle: "produkter-oelvand.mysmoothie-jordbaer.png",
    imagePlural: "produkter-oelvand.6660-kasse.png",
    headlineXtra: "Med jordbær, æble, druesaft, banan, appelsin og aroniabær.",
    description: () =>
      "Ingen pant. Indhold: 30% jordbærpuré, 26% æblejuice*, 18% druesaft*, 15% bananpuré, 9% appelsinjuice*, 2% aroniabær juice*. *fra koncentrat.",
  },
  {
    varenr: 1000029,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2020</React.Fragment>
    ),
    expiredSize: "12x25cl",
    oldVareNr: "6680",
    name: "MySmoothie Granatæble",
    imageSingle: "produkter-oelvand.mysmoothie-granataeble.png",
    imagePlural: "produkter-oelvand.6680-kasse.png",
    headlineXtra: "Med granatæble, druesaft, æble, banan, hindbær og blåbær.",
    description: () =>
      "Ingen pant. Indhold: 25% granatæble, 23% druesaft*, 22% æblejuice*, 19% bananpuré, 6% hindbærpuré, 5% blåbær. *fra koncentrat.",
  },
  {
    varenr: 1000030,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2020</React.Fragment>
    ),
    expiredSize: "12x25cl",
    oldVareNr: "6770",
    name: "MySmoothie Acai",
    imageSingle: "produkter-oelvand.mysmoothie-acai.png",
    imagePlural: "produkter-oelvand.6770-kasse.png",
    headlineXtra:
      "Med Acai bær, æble, banan, hindbær, blåbær, hyldeblomst og citron.",
    description: () =>
      "Ingen pant. Indhold: 38% æblejuice*, 17% bananpuré, 16% æblepuré, 11% hindbærpuré, 8% blåbær, 5% Açaipuré, 4% hyldebærjuice*, 1% citronjuice*. *fra koncentrat.",
  },
  {
    varenr: 1000031,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra importøren november 2019</React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "4865",
    name: "Edelweiss Dunkel",
    keywords: "fustage fustager fustace fus fad fadølsfustage fadøl eddelweis",
    imageSingle: "produkter-fadoel.edelweiss-dunkel.png",
    imagePlural: "produkter-fadoel.edelweiss-fustage.png",
    // fadoelsType: 'Mørk Pilsner',
    land: "Østrig",
    landKort: "AT",
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          Edelweiss Dunkel er naturligt sløret og i glasset og har en flot
          kastanje brun farve. Smagen minder om vanilje, ingefær og har en aroma
          af ristet malt.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000032,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>
        Find mere information om leje af gratis sodavandsautomat på vores side
        www.sodavand.dk
      </React.Fragment>
    ),
    oldVareNr: "",
    name: "Sodavandsautomat / Sodavandsmaskine",
    keywords: "Sodavandsautomat Sodavandsmaskine automat automater",
    imageSingle: "style.sodavandsautomat-h600.png",
    // imagePlural: 'produkter-fadoel.edelweiss-fustage.png',
    externalurl: "https://sodavand.dk/sodavandsautomat",
  },
  {
    varenr: 1000033,
    expired: true,
    name: "To Øl Hundelufterbajer",
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment maj 2020</React.Fragment>
    ),
    expiredSize: "30 l",
    oldVareNr: "25235",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa type k keykeg dansk danske danmark 30l 30 l liter",
    imageSingle: "produkter-fadoel.to-oel-hundelufter-glas.png",
    imagePlural: "produkter-fadoel.to-oel-fustage.png",
    // fadoelsType: 'India Pale Ale',
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          To Øl Hundelufterbajer fadøl er en session IPA med en god frisk
          bitterhed og Mosaic humlen tilføjer en smag af mango og passionsfrugt.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        To Øl Hundelufterbajer fadøl er en session IPA med en god frisk
        bitterhed og Mosaic humlen tilføjer en smag af mango og passionsfrugt.
      </React.Fragment>
    ),
  },
  {
    varenr: 1000040,
    expired: true,
    name: "Carlsberg OBH Draughtmaster",
    expiredMessage: () => (
      <React.Fragment>
        5 liter fustager til OBH Draughtmaster er fuldstændigt udgået af
        produktion. Vi har ingen på lager og vi får ikke flere hjem. Udgået helt
        af produktion februar 2020.
      </React.Fragment>
    ),
    expiredSize: "5l",
    oldVareNr: "2614",
    keywords:
      "5l druaugmaster draughtmaster drauftmaster små fustager 5 l ace liters fustage fadøl 5liter draftmaster",
    productTypeFadoel: true,
    draughtmaster: true,
    simpleItem: true,
    invoiceDisplay: "FustageItemRow",
    inStockText: () =>
      "5 liter fustager til OBH Draughtmaster er fuldstændigt udgået af produktion. Vi har ingen på lager og vi får ikke flere hjem",
    description: () => (
      <React.Fragment>
        <Paragraph>
          Holdbarhed på nuværende parti:
          <br />
          <Datoer type="draughtmaster" />, eller 21 dage efter første servering.
        </Paragraph>
        <Span fontSize="12px" marginTop="10px">
          Sendes med Post Nord. Levering gratis ved min. 4 stk., ellers{" "}
          <Price varenr="620" momsIndication />. Fremme indenfor 3 hverdage
          eller hent selv gratis.
        </Span>
      </React.Fragment>
    ),
    packImages: [
      {
        name: "fadoel.draughtmaster-kasse-aaben-top1.png",
        // imageDescription: () => '5 liters Carlsberg Pilsner fustage i salgsforpakning',
      },
      {
        name: "fadoel.draughtmaster-kasse-aaben-laag1.png",
        // imageDescription: () => 'Som det ses på billedet medfølger slange',
      },
      {
        name: "fadoel.draughtmaster-fustage.png",
        // imageDescription: () => '5 liters Carlsberg Pilsner fustage klar til montering i OBH Draughtmaster',
      },
    ],
    imageSingle: "fadoel.draughtmaster-kasse-aaben-top1.png",
    imageMaxHeight: "200px",
    searchDisplay: SmallView,
    productTypePack: true,
    recieptItemView: RecieptPackEntryView,
  },
  {
    varenr: 1000041,
    expired: true,
    name: "Jacobsen Extra Pilsner",
    expiredMessage: () => (
      <React.Fragment>Udgået fra bryggeriet juni 2020</React.Fragment>
    ),
    expiredSize: "25l",
    oldVareNr: "8895",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 25l 25 l liter",
    imageSingle: "produkter-fadoel.jacobsen-extra-pilsner.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    // fadoelsType: 'Stærk Pilsner',
    land: "Danmark",
    landKort: "DK",
    related: [8115, 3676, 10615, 10605],
    productPageDescription: () => (
      <React.Fragment>
        <Heading3>
          Økologisk pilsner brygget med inspiration fra Tjekkiet og Tyskland
        </Heading3>
        <Paragraph>
          Jacobsen Extra Pilsner brygges på Husbryggeriet Jacobsen og har været
          på markedet siden 2007. Det er en stærk og fyldig pilsnerøl brygget på
          nordiske økologiske råvarer som fx vildtvoksende havtorn. Den har en
          alkoholstyrke på 5,5%. Øllet har en strågul farve og et tæt hvidligt
          cremet skum. Den har en fin kornduft med et strejf af jordbær. Den
          passer perfekt til de danske frokostretter.
        </Paragraph>
        <Paragraph>
          Jacobsen Extra Pilsner er en økologisk pilsner brygget på økologiske
          pilsner-, münchener- og caramünchenermalt. Den brygges med inspiration
          fra Tjekkiet og det sydlige Tyskland. På humlesiden er der brugt de
          økologiske humlesorter Spalter Select og Hallertau Tradition. Det er
          en øl, der er rig på humle. Den har en dyb gylden farve og udvikler et
          tæt hvidligt cremet skum. Duften er præget af noter af korn og malt
          fulgt af et fint strejf af humle og havtornjuice, der aromatisk minder
          om skovjordbær.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>
              Jacobsen Extra Pilsner er ideel til det danske frokostbord
            </Heading3>
            <Paragraph>
              Jacobsen Brown Ale er en øl, der bør nydes ved ca. 6-10 °C.
              Undergæren stammer oprindeligt fra Schweiz og giver øllet en
              behagelig friskhed. Den markante efterbitterhed fungerer perfekt i
              forhold til de typiske danske frokostretter, som ofte indeholder
              mange forskellige smagsindtryk fra fx marineret sild, æg, diverse
              salater, kødpålæg, leverpostej, frikadeller og ost. Øllets fylde
              og markerede bitterhed skaber et godt modspil til de mange uens
              smagsindtryk. En serveringstemperatur på 6-8°C er ideel.
            </Paragraph>
            <Paragraph>
              Jacobsen Extra Pilsner kan også serveres til laks, tapas, kylling
              eller traditionelle danske middagsretter.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    gas: "CO2",
    pressure: "2,5-3 bar",
    popupDescription: () => (
      <React.Fragment>
        Brygges på Husbryggeriet Jacobsen.
        <br />
        <br />
        Stærk og fyldig pilsnerøl brygget på nordiske økologiske råvarer som fx
        vildtvoksende havtorn.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000042,
    expired: true,
    name: "Maribo Classic",
    expiredMessage: () => (
      <React.Fragment>Udgået fra bryggeriet juli 2020</React.Fragment>
    ),
    expiredSize: "30x33cl",
    oldVareNr: "1650",
    keywords: "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.maribo-classic-flaske.png",
    imagePlural: "produkter-oelvand.1650-kasse.png",
  },
  {
    varenr: 1000044,
    expired: true,
    name: "Coca-Cola Light Dåse",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2020</React.Fragment>
    ),
    expiredSize: "24x33cl",
    oldVareNr: "5363",
    keywords:
      "sukkerfri sukker fri 24 24stk stk dåser 33cl 33 cl 24x33cl 24x33 coca cola zero light sodavand ramme",
    imageSingle: "produkter-oelvand.coca-cola-light-33cl-daase-1.png",
    imagePlural: "produkter-oelvand.coca-cola-light-33cl-daase-ramme-1.png",
  },
  {
    varenr: 1000046,
    expired: true,
    name: "Nohrlund Øko | Den Gule",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2020</React.Fragment>
    ),
    expiredSize: "20 l",
    oldVareNr: "24035",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg Rom Hyben Havtorn dansk danske danmark 20l 20 liter l cocktails drinks økologisk",
    // url: '/nohrlund-cocktails',
    smag: "Rom, Hyben & Havtorn",
    imageSingle: "produkter-fadoel.nohrlund-den-gule-glas.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'Cocktail',
    gas: "CO2",
    pressure: "2,5-3 bar",
    // // royalUnibrew: true,
    popupDescription: () => (
      <React.Fragment>
        Norhlund Rom, Hyben &amp; Havtorn har 3 nuancer der giver en perfekt
        nordisk cocktail. Havtorn giver frisk syre. Hyben giver en fyldig
        frugtsmag og sidst giver den lyse rom en krydret dybde.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000047,
    expired: true,
    name: "Kissmeyer Two Front Teeth Jule Ale",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten oktober 2020</React.Fragment>
    ),
    expiredSize: "20 l",
    oldVareNr: "40975",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl julebryg juleøl dansk danske danmark 20l 20 liter l dark ipa",
    imageSingle: "produkter-fadoel.kissmeyer-two-front-teeth-jule-ale.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    // fadoelsType: 'Mørk Ale',
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
      </React.Fragment>
    ),
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          Kissmeyer Two Front Teeth Jule Ale er en markant anderledes og unik
          julebryg med en unik kombination af frugt, malt, krydret belgiske
          toner og krop.
        </Paragraph>
        <Paragraph>
          Selvom denne fadøl er brygget specielt til den danske jul, er Two
          Front Teeth også en lækker, kompleks og forfriskende øl, der passer
          godt til mange andre retter - eller som ren nydelse alene.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Kissmeyer Two Front Teeth Jule Ale er en markant anderledes og unik
          julebryg med en unik kombination af frugt, malt, krydret belgiske
          toner og krop.
        </Paragraph>
        <Paragraph>
          Selvom denne fadøl er brygget specielt til den danske jul, er Two
          Front Teeth også en lækker, kompleks og forfriskende øl, der passer
          godt til mange andre retter - eller som ren nydelse alene.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000048,
    expired: true,
    name: "7-Up Free",
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment oktober 2020</React.Fragment>
    ),
    expiredSize: "30x25cl",
    oldVareNr: "4320",
    keywords:
      "7 7up seven up sevenup flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle: "produkter-oelvand.7up-free-25cl.png",
    imagePlural: "produkter-oelvand.4320-kasse.png",
  },
  {
    varenr: 1000049,
    expired: true,
    name: "Ceres Havskum",
    expiredMessage: () => (
      <React.Fragment>
        Udgået fra producenten juni 2020. Ingen kasser på lager, udsolgt i hele
        landet.
      </React.Fragment>
    ),
    expiredSize: "30x33cl",
    oldVareNr: "4790",
    keywords: "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.ceres-havskum-33cl-flaske.png",
    // imagePlural: 'produkter-oelvand.royal-classic-33cl-kasse.png',
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px">
          Lagerføres ikke, tages kun hjem på bestilling og mod forud betaling,
          leveringstid 5 dage
        </Span>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000050,
    expired: true,
    name: "Schweppes Lemon Fusion Dåse",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten oktober 2020</React.Fragment>
    ),
    expiredSize: "24x33cl",
    oldVareNr: "2173",
    keywords:
      "sodavand aluminium alu dåser 33cl 33 cl 24x33cl 24x33 sweppes schweppers",
    imageSingle: "produkter-oelvand.schweppes-lemon-fusion-daase-33cl.png",
  },
  {
    varenr: 1000054,
    expired: true,
    name: "Nørrebro Julefrokost Kassen",
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment november 2020</React.Fragment>
    ),
    expiredSize: "18x33cl",
    oldVareNr: "8000",
    keywords:
      "julebryg juleøl bryghus øl flasker glasflasker 33cl 33 cl 18x33cl 18x33 papkasser",
    imagePlural: "produkter-oelvand.noerrebro-julefrokost-kassen.png",
    usePluralImage: true,
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        {/*
        <Span blue block fontSize="13px" paddingTop="5px">
          Kan leveres fra <Datoer type="julebryg" />
        </Span>
        */}
        <Span block fontSize="13px" paddingTop="5px">
          Tryk på navnet ovenfor for at læse mere om kassen.
        </Span>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          En perfekt julekasse til din julefrokost fra Nørrebro Bryghus.
          Nørrebro Julefrokost Kassen består af 5 forskellige øl fra det gode
          bryggeri:
        </Paragraph>
        <Paragraph>
          - Nørrebros Julebryg
          <br />
          - Jule Dubbel
          <br />
          - Nørrebro Jule IPA
          <br />
          - Nørrebro Pilsner
          <br />- Nørrebro Bombay India Pale Ale
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000055,
    expired: true,
    name: "Aqua d'or blid brus palle",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten april 2021</React.Fragment>
    ),
    expiredSize: "6x125cl",
    oldVareNr: "9931",
    keywords:
      "aquador aqua aqua'd'or aquad'or aqua'd'or aquad'or dor auqa dór kildevand vand flasker plastflasker plastikflasker 125cl 125 cl 6x125cl 6x125 100 x 6 x 125cl 600",
    imageSingle: "produkter-oelvand.aquador-brus-125cl.png",
  },
  {
    varenr: 1000056,
    expired: true,
    name: "Master Brew",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten 1. januar 2021</React.Fragment>
    ),
    expiredSize: "30x33cl",
    oldVareNr: "2750",
    imageSingle: "produkter-oelvand.carlsberg-master-brew-flaske.png",
    imagePlural: "produkter-oelvand.2750-kasse.png",
  },
  {
    varenr: 1000057,
    expired: true,
    name: "Staropramen Premium",
    expiredMessage: () => <React.Fragment>Udgået fra sortiment</React.Fragment>,
    expiredSize: "24x33cl",
    oldVareNr: "8830",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.staropramen-premium-flaske.png",
    imagePlural: "produkter-oelvand.8830-kasse.png",
  },
  {
    varenr: 1000058,
    expired: true,
    name: "Egekilde Blåbær brus",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten april 2021</React.Fragment>
    ),
    expiredSize: "24x50cl",
    oldVareNr: "4082",
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50",
    imageSingle: "produkter-oelvand.4082-flaske.png",
    imagePlural: "produkter-oelvand.4082-kasse.png",
  },
  {
    varenr: 1000059,
    expired: true,
    name: "Nørrebro Øko Böhmer Pilsner",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten maj 2021</React.Fragment>
    ),
    expiredSize: "20l",
    oldVareNr: "8005",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l bryghus økologisk",
    // imageSingle: 'produkter-oelvand.noerrebro-oeko-bohmer-pilsner.png',
    imagePlural: "produkter-fadoel.noerrebro-fustage.png",
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'Pilsner',
    gas: "CO2",
    pressure: "2,5-3 bar",
    related: [18615, 8015, 4625, 19615],
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          Nørrebro Øko Böhmer Pilsner er en økologisk øl, som er kraftigt
          inspireret af den Böhmiske Pilsner tradition.
        </Paragraph>
        <Paragraph>
          Smagen er rund og utrolig fyldig dog uden påtrængende bitterhed. Dansk
          og økologisk.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Nørrebro Øko Böhmer Pilsner er en økologisk øl, som er kraftigt
          inspireret af den Böhmiske Pilsner tradition.
        </Paragraph>
        <Paragraph>
          Smagen er rund og utrolig fyldig dog uden påtrængende bitterhed. Dansk
          og økologisk.
        </Paragraph>
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000060,
    expired: true,
    name: "Carlsberg Kurvand brus",
    inStockText: () => (
      <React.Fragment>
        Udgået fra producenten 1. januar 2021. Vi anbefaler som alternativ
        Ramlösa, San Pellegrino, Egekilde eller Aqua d&apos;or
      </React.Fragment>
    ),
    expiredMessage: () => (
      <React.Fragment>
        Udgået fra producenten 1. januar 2021. Vi anbefaler som alternativ
        Ramlösa, San Pellegrino, Egekilde eller Aqua d&apos;or
      </React.Fragment>
    ),
    expiredSize: "24x50cl",
    oldVareNr: "2032",
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 kurvand natur",
    imageSingle: "produkter-oelvand.2032-flaske.png",
    imagePlural: "produkter-oelvand.2032-kasse.png",
  },
  {
    varenr: 1000061,
    expired: true,
    name: "Carlsberg Kurvand Citrus brus",
    inStockText: () => (
      <React.Fragment>
        Udgået fra producenten 1. januar 2021. Vi anbefaler som alternativ
        Ramlösa, San Pellegrino, Egekilde eller Aqua d&apos;or
      </React.Fragment>
    ),
    expiredMessage: () => (
      <React.Fragment>
        Udgået fra producenten 1. januar 2021. Vi anbefaler som alternativ
        Ramlösa, San Pellegrino, Egekilde eller Aqua d&apos;or
      </React.Fragment>
    ),
    expiredSize: "24x50cl",
    oldVareNr: "2042",
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 24x50cl 24x50 kurvand natur",
    imageSingle: "produkter-oelvand.2042-flaske.png",
    imagePlural: "produkter-oelvand.2042-kasse.png",
  },
  {
    varenr: 1000062,
    expired: true,
    name: "Aqua d'or Zero Ingefær/Citron brus",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juli 2021</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "21162",
    keywords:
      "aquador aqua aqua'd'or aquad'or aqua'd'or aquad'or dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-ingefaer-citron-50cl.png",
    imagePlural: "produkter-oelvand.aquador-ingefaer-citron-50cl-kolli.png",
  },
  {
    varenr: 1000063,
    expired: true,
    name: "Vitamin Well Celebrate",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juli 2021</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "9620",
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50",
    imageSingle: "produkter-oelvand.vitamin-well-celebrate-flaske-50cl.png",
    imagePlural: "produkter-oelvand.vitamin-well-celebrate-kasse.png",
    smag: "Mango/Ananas",
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Vitamin D</Td>
                <Td textRight>4µg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>7,2mg</Td>
                <Td textRight>60%</Td>
              </tr>
              <tr>
                <Td>Niacin</Td>
                <Td textRight>8mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Folsyre</Td>
                <Td textRight>50µg</Td>
                <Td textRight>25%</Td>
              </tr>
              <tr>
                <Td>Vitamin B12</Td>
                <Td textRight>1,25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Biotin</Td>
                <Td textRight>25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Pantothensyre</Td>
                <Td textRight>2,4mg</Td>
                <Td textRight>40%</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12}>
            <Paragraph>
              Limited Edition. Støttet af håndboldspiller Mikkel Hansen mod
              mobning. Med købet af denne flaske støtter du kampen mod mobning.
              For hver købt flaske doneres 1 kr til MH24.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000064,
    expired: true,
    name: "Frem Citron",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juli 2021</React.Fragment>
    ),
    expiredSize: "24x25cl",
    oldVareNr: "1070",
    showExpired: true,
    keywords:
      "frem sodavand fremsodavand citronvand ribe haribo harboe flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-citron-25cl.png",
    imagePlural: "produkter-oelvand.1070-kasse.png",
  },
  {
    varenr: 100000,
    expired: true,
    name: "Somersby Apple Cider",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten april 2021</React.Fragment>
    ),
    expiredSize: "25 l",
    oldVareNr: "2815",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl cider sommersby sommersbye dansk danske danmark",
    imageSingle: "produkter-fadoel.somersby-apple-cider.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    // fadoelsType: 'Cider',
    land: "Danmark",
    landKort: "DK",
    productPageDescription: () => (
      <React.Fragment>
        <Heading3>Populært alternativ til øl og vin</Heading3>
        <Paragraph>
          Somersby Apple Cider eller Somersby Æble er en alkoholisk cider, der
          indeholder 4,5 % alkohol. I 2008 lancerede Carlsberg Somersby Apple.
          Æblecideren er en cider med 15% æblejuice. Indtil videre har den søde
          boblende drik været en stor succes.
        </Paragraph>
        <Paragraph>
          Somersby Apple Cider er Carlsberg-gruppens bedst sælgende cider. I de
          senere år har cider oplevet en dramatisk renæssance. I dag er det et
          meget populært alternativ til øl og vin.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Heading3>
              Alkoholprocent på 4,5 procent, der svarer til en almindelig øl
            </Heading3>
            <Paragraph>
              Æblecider er blevet brygget i England i mange år. Cider [sajder]
              er gæret æblemost, men kan også være produceret på både æbler og
              pærer. Somersby Apple Cider er tilpasset til den danske smag.
              Somersby cideren har en alkoholprocent på 4,5 procent, der svarer
              til en almindelig øl. Der er en skøn sommeræble-aroma, og smagen
              afsluttes med en behagelig syre. Farven er gylden og med perlende
              bobler. Somersby æble er en dejlig frisk cider, især når den
              serveres iskold. Den kan også indgå i drinks.
            </Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    gas: "CO2",
    pressure: "2,5-3 bar",
    popupDescription: () => (
      <React.Fragment>
        Cider med 15% æblejuice. <br />
        <br />
        Populært alternativ til øl og vin.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000065,
    expired: true,
    name: "Aqua d'or Citron/Ananas brus",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2021</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "21172",
    keywords:
      "aquador aqua aqua'd'or aquad'or aqua'd'or aquad'or dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-brus-citron-ananas-50cl.png",
    imagePlural: "produkter-oelvand.21172-kasse.png",
  },
  {
    varenr: 1000066,
    expired: true,
    name: "Frem Klar Cola",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2021</React.Fragment>
    ),
    expiredSize: "24x25cl",
    oldVareNr: "1340",
    showExpired: true,
    keywords:
      "frem sodavand fremsodavand ribe haribo harboe flasker glasflasker 25cl 25 cl 24x25cl 24x25 jolly",
    imageSingle: "produkter-oelvand.frem-klar-cola-1.png",
    imagePlural: "produkter-oelvand.1030-kasse.png",
  },
  {
    varenr: 1000067,
    expired: true,
    name: "Frem Citron Light",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2021</React.Fragment>
    ),
    expiredSize: "24x25cl",
    oldVareNr: "1080",
    showExpired: true,
    keywords:
      "sukkerfri sukker fri frem sodavand fremsodavand citronvand ribe haribo harboe flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-citron-light-25cl.png",
    imagePlural: "produkter-oelvand.1080-kasse.png",
  },
  {
    varenr: 1000068,
    expired: true,
    name: "Frem Cola",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2021</React.Fragment>
    ),
    expiredSize: "24x25cl",
    oldVareNr: "1350",
    showExpired: true,
    keywords:
      "frem sodavand fremsodavand ribe haribo harboe flasker glasflasker 25cl 25 cl 24x25cl 24x25 jolly",
    imageSingle: "produkter-oelvand.frem-cola-25cl.png",
    imagePlural: "produkter-oelvand.1350-kasse.png",
  },
  {
    varenr: 1000069,
    expired: true,
    name: "Frem Cola Light",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2021</React.Fragment>
    ),
    expiredSize: "24x25cl",
    oldVareNr: "1360",
    showExpired: true,
    keywords:
      "sukkerfri sukker fri frem sodavand fremsodavand ribe haribo harboe flasker glasflasker 25cl 25 cl 24x25cl 24x25 jolly",
    imageSingle: "produkter-oelvand.frem-cola-light-25cl.png",
    imagePlural: "produkter-oelvand.1360-kasse.png",
  },
  {
    varenr: 1000070,
    expired: true,
    name: "Kissmeyer Garage Eclipsed Kiss",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2021</React.Fragment>
    ),
    expiredSize: "20l",
    oldVareNr: "40845",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l",
    imageSingle: "produkter-fadoel.40845-flaske.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'Stout',
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    // // royalUnibrew: true,
    smallDescription: () => (
      <React.Fragment>
        <Span fontSize="12px" marginTop="10px">
          Craftbeer | American Style Stout
        </Span>
      </React.Fragment>
    ),
    productPageDescription: () => (
      <React.Fragment>
        Kissmeyer Eclipsed Kiss er en American Style Stout. En intens, fyldig og
        ristet stout med rige humlearomaer og smag, der balancerer
        maltkaraktererne. Rig på alle aspekter, men stadig en glat øl at drikke.
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Kissmeyer Eclipsed Kiss er en American Style Stout. En intens, fyldig og
        ristet stout med rige humlearomaer og smag, der balancerer
        maltkaraktererne. Rig på alle aspekter, men stadig en glat øl at drikke.
      </React.Fragment>
    ),
  },
  {
    varenr: 1000071,
    expired: true,
    name: "Carlsberg Kurvand",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2021</React.Fragment>
    ),
    expiredSize: "30x25cl",
    oldVareNr: "2030",
    keywords:
      "danskvand dansk vand apollinaris appollinaris  apolinaris appolinaris  brus flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle: "produkter-oelvand.carlsberg-kurvand-25cl.png",
    imagePlural: "produkter-oelvand.2030-kasse.png",
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px" lineHeight="1.5em">
          Udgår fra bryggeriet. Vi anbefaler{" "}
          <LinkStyled
            anchor
            underline
            to="/bestil/flasker-og-daaser/sodavand/kategori/sodavand-glas/vare/4050"
          >
            Ramløse 33cl glas
          </LinkStyled>
          ,{" "}
          <LinkStyled
            anchor
            underline
            to="/bestil/flasker-og-daaser/sodavand/kategori/sodavand-glas/vare/4030"
          >
            Egekilde 30cl glas
          </LinkStyled>{" "}
          eller{" "}
          <LinkStyled
            anchor
            underline
            to="/bestil/flasker-og-daaser/kildevand/kategori/kildevand-med-brus/vare/9033"
          >
            Aqua d&apos;or 30cl brus plast
          </LinkStyled>
        </Span>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000072,
    expired: true,
    name: "Carlsberg Kurvand Citrus",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2021</React.Fragment>
    ),
    expiredSize: "30x25cl",
    oldVareNr: "2040",
    keywords:
      "danskvand dansk vand apollinaris appollinaris  apolinaris appolinaris  brus flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle: "produkter-oelvand.carlsberg-kurvand-citrus-25cl.png",
    imagePlural: "produkter-oelvand.2040-kasse.png",
    smallDescription: () => (
      <React.Fragment>
        <Span blue block fontSize="13px" paddingTop="5px" lineHeight="1.5em">
          Udgår fra bryggeriet. Vi anbefaler{" "}
          <LinkStyled
            anchor
            underline
            to="/bestil/flasker-og-daaser/sodavand/kategori/sodavand-glas/vare/4060"
          >
            Ramløse Citrus 33cl glas
          </LinkStyled>{" "}
          eller{" "}
          <LinkStyled
            anchor
            underline
            to="/bestil/flasker-og-daaser/sodavand/kategori/sodavand-glas/vare/4040"
          >
            Egekilde Citrus 30cl glas
          </LinkStyled>
        </Span>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000073,
    expired: true,
    name: "To Øl Mr. Orange",
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment september 2021</React.Fragment>
    ),
    expiredSize: "20l",
    oldVareNr: "25435",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa type k keykeg dansk danske danmark 20l 20 liter l",
    imageSingle: "produkter-fadoel.to-oel-mr-orange-glas.png",
    imagePlural: "produkter-fadoel.to-oel-fustage.png",
    // fadoelsType: 'India Pale Ale',
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          To Øl Mr. Orange fadøl er en session IPA med kaki frugt, appelsinskal
          og re-fermenteret med To Øl Instant Crush Brett.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        To Øl Mr. Orange fadøl er en session IPA med kaki frugt, appelsinskal og
        re-fermenteret med To Øl Instant Crush Brett.
      </React.Fragment>
    ),
  },
  {
    varenr: 1000074,
    expired: true,
    name: "Franziskaner Hefe Weissbier",
    expiredMessage: () => (
      <React.Fragment>Udgået fra importøren september 2021</React.Fragment>
    ),
    expiredSize: "30l",
    oldVareNr: "8635",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter weissbier",
    imageSingle: "produkter-fadoel.franziskaner-hefe.png",
    imagePlural: "produkter-fadoel.franziskaner-fustage.png",
    // fadoelsType: 'Hvedeøl',
    land: "Tyskland",
    landKort: "DE",
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          Franziskaner Hefe er en tysk hvedeøl med en lidt uklar korngul farve
          med en kraftig hvid skumkrone, som hurtigt forsvinder. Den har en
          minimal kulsyreudvikling. Den er frisk og frugtig og har ingen
          bitterhed. Den er velegnet til let sommermad eller fx spansk tapas.
          Franziskaner er en af de få øl, der må sælges til Oktoberfesten i
          München.
        </Paragraph>
      </React.Fragment>
    ),
    productPageXtraDescription: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <Heading3>
              Typisk sydtysk weizenbier med duft af banan og nelliker
            </Heading3>
            <Paragraph>
              Franziskaner Hefe er en overgæret og ufiltreret hvedeøl med en
              korngul farve. Skummet er hvidt og sprudlende, men holder ikke
              særlig længe. Franziskaner Hefe brygges efter det bayerske
              ”Reinheitsgebot” fra 1516. Der står, at øl kun må brygges af malt,
              humle og vand. Intet andet. Franziskaner Hefe er derfor en typisk
              sydtysk weizenbier brygget på 2/3 hvedemalt og 1/3 bygmalt. Den er
              frisk og frugtig med en duft, der minder om banan og nelliker. Den
              har ingen bitterhed.
            </Paragraph>
          </Col>
          <Col xs={12} md={6}>
            <Heading3>
              TIP: Velegnet til let sommermad og fx spansk tapas
            </Heading3>
            <Paragraph>
              Franziskaner Hefe serveres bedst iskold. Den er velegnet til lette
              sommerretter.
            </Paragraph>
            <Heading3>Bestilling af Franziskaner Hefe</Heading3>
            <Paragraph>Varen er udgået.</Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000075,
    expired: true,
    name: "Nohrlund Øko | Den Røde",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten september 2021</React.Fragment>
    ),
    expiredSize: "20l",
    oldVareNr: "24015",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg Vodka Jordbær Rabarber dansk danske danmark 20l 20 liter l cocktails drinks økologisk",
    smag: "Vodka, Jordbær & Rabarber",
    imageSingle: "produkter-fadoel.nohrlund-den-roede-glas.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'Cocktail',
    gas: "CO2",
    pressure: "2,5-3 bar",
    popupDescription: () => (
      <React.Fragment>
        Denne cocktail fra Nohrlund smager af sommer fuld af jordbær og
        rabarber. Der bliver med nøje udvalgt de jordbær, som er mest modne og
        søde. Dette sammensat med sprød rabarber, vanilje og delikat vodka giver
        en perfekt sommercocktail.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000076,
    expired: true,
    name: "Nohrlund Øko | Den Hvide",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten september 2021</React.Fragment>
    ),
    expiredSize: "20l",
    oldVareNr: "24025",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg Gin Hyldeblomst Ingefær dansk danske danmark 20l 20 liter l cocktails drinks økologisk",
    smag: "Gin, Hyldeblomst & Ingefær",
    imageSingle: "produkter-fadoel.nohrlund-den-hvide-glas.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    popupDescription: () => (
      <React.Fragment>
        Denne læskende cocktail har en fantastisk balance af Ingefær og
        Hyldeblomst. Cocktailen er kraftig og sød og samspillet med den intense
        Gin, krydret enebær, diskret citrus, ingefær og hyldeblomst giver dig en
        cocktail du skal smage mere end én gang.
      </React.Fragment>
    ),
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'Cocktail',
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000077,
    expired: true,
    name: "Herslev Øko India Dark Ale",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten september 2021</React.Fragment>
    ),
    expiredSize: "20l",
    oldVareNr: "10875",
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l økologisk dark ipa herslev bryghus",
    imageSingle: "produkter-fadoel.herslev-label-ida.jpg",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    // fadoelsType: 'Mørk Ale',
    land: "Danmark",
    landKort: "DK",
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          IDA, som vi kalder den, er en mørk IPA med en behagelig maltkant, som
          balancerer godt med de gavmilde mængder aromatisk humle. Duften er
          frisk og frugtig og farven er mørk kastaniefarvet. Smagen er
          afbalanceret mellem New Zealandsk humle og gode, ristede malte fra
          bryghusets egne marker - blød med med masser af kant. Fremragende til
          braserede retter, hvor humlens bitterhed kan spille op mod de tunge
          smagsnuancer - eller som en helt almindelig tørstslukker. En af vores
          meget populære øl hos både mænd og især kvinder.
        </Paragraph>
        <Paragraph>
          Herslev Bryghus er et økologisk gårdbryggeri i landsbyen Herslev ved
          Roskilde Fjord. Her brygges øl efter økologiske foreskrifter med fokus
          på lokale råvarer. Grundstenen i alle bryghusets øl er det økologiske
          korn, som bryggers på markerne omkring gården.
        </Paragraph>
      </React.Fragment>
    ),
    gas: "CO2",
    pressure: "2,5-3 bar",
    popupDescription: () => (
      <React.Fragment>
        Mørk India Ale med frisk og frugtig duft med en mørk kastaniefarvet
        glød.
        <br />
        <br />
        Humle fra New Zealand og gode, ristede malte fra bryghusets egne marker.
      </React.Fragment>
    ),
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000078,
    expired: true,
    name: "Vitamin Well Reload uden brus",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten september 2021</React.Fragment>
    ),
    expiredSize: "12x50cl",
    oldVareNr: "9640",
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50",
    imageSingle: "produkter-oelvand.9640-flaske.png",
    imagePlural: "produkter-oelvand.9640-kasse.png",
    smag: "Citron/Lime",
    indhold: () => (
      <React.Fragment>
        <Row>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
              </tr>
              <tr>
                <Td>Energi</Td>
                <Td textRight>357 kJ/ 85 kcal</Td>
              </tr>
              <tr>
                <Td>Kulhydrater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>heraf sukkerater</Td>
                <Td textRight>21g</Td>
              </tr>
              <tr>
                <Td>Fedt</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Protein</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Fibrer</Td>
                <Td textRight>0g</Td>
              </tr>
              <tr>
                <Td>Salt</Td>
                <Td textRight>0,13g</Td>
              </tr>
            </Table>
          </Col>
          <Col xs={12} lg={6}>
            <Table lines2>
              <tr>
                <Td colspan="2" bold textRight>
                  500ml
                </Td>
                <Td bold textRight>
                  ADI
                </Td>
              </tr>
              <tr>
                <Td>Niacin</Td>
                <Td textRight>8mg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Pantothensyre</Td>
                <Td textRight>2,4mg</Td>
                <Td textRight>40%</Td>
              </tr>
              <tr>
                <Td>Biotin</Td>
                <Td textRight>25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Vitamin B12</Td>
                <Td textRight>1,25µg</Td>
                <Td textRight>50%</Td>
              </tr>
              <tr>
                <Td>Vitamin D</Td>
                <Td textRight>4µg</Td>
                <Td textRight>80%</Td>
              </tr>
              <tr>
                <Td>Vitamin E</Td>
                <Td textRight>7,2µg</Td>
                <Td textRight>60%</Td>
              </tr>
              <tr>
                <Td>Folsyre</Td>
                <Td textRight>50µg</Td>
                <Td textRight>25%</Td>
              </tr>
            </Table>
          </Col>
        </Row>
      </React.Fragment>
    ),
  },
  {
    varenr: 1000079,
    expired: true,
    name: "Herslev Øko Hoppy Pils",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten september 2021</React.Fragment>
    ),
    expiredSize: "20l",
    oldVareNr: "10605",
    // herslevBryghus: true,
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 20l 20 liter l økologisk herslev bryghus",
    imageSingle: "produkter-fadoel.herslev-label-hoppy-pils.jpg",
    imagePlural: "produkter-fadoel.herslev-fustage1.png",
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'Pilsner',
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    related: [8115, 3675, 10615, 4625],
    productPageDescription: () => (
      <React.Fragment>
        <Paragraph>
          Herslev Øko Hoppy Pils er en tyskinspireret pilsner med mere tørhumle.
          Denne fadøl har en frisk og frugtig smag som læner sig imod en IPA.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Herslev Øko Hoppy Pils er en tyskinspireret pilsner med mere tørhumle.
        Denne fadøl har en frisk og frugtig smag som læner sig imod en IPA.
      </React.Fragment>
    ),
  },
  {
    varenr: 1000080,
    expired: true,
    name: "Brdr. Adelhardt Øko Lemon",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten oktober 2021</React.Fragment>
    ),
    expiredSize: "24x27,5cl",
    oldVareNr: "6070",
    keywords:
      "brødrene adelhart adelhard sodavand flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papbakke økologisk",
    imageSingle: "produkter-oelvand.adelhardt-lemon-flaske.png",
    // imagePlural: 'produkter-oelvand.6070-kasse.png',
  },
  {
    varenr: 1000082,
    expired: true,
    name: "Frem Lemon Light",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten oktober 2021</React.Fragment>
    ),
    expiredSize: "24x25cl",
    oldVareNr: "1170",
    showExpired: true,
    keywords:
      "sukkerfri sukker fri frem sodavand fremsodavand ribe haribo harboe flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-lemon-light-25cl.png",
    imagePlural: "produkter-oelvand.1170-kasse.png",
  },
  {
    varenr: 1000083,
    expired: true,
    name: "Carlsberg 47",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten november 2021</React.Fragment>
    ),
    expiredSize: "30x33cl",
    oldVareNr: "2690",
    keywords: "øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 plastkasser",
    imageSingle: "produkter-oelvand.carlsberg-47-33cl.png",
    inStockText: () => (
      <React.Fragment>
        Udsolgt. Kommer igen <Datoer type="julebrygNextYear" />
      </React.Fragment>
    ),
  },
  {
    varenr: 1000084,
    expired: true,
    name: "Nørrebro Øko Dronning Louises Paaske Ale",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten november 2021</React.Fragment>
    ),
    expiredSize: "20l",
    oldVareNr: "8085",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa dansk danske danmark 20l 20 liter l bryghus økologisk",
    // imageSingle: 'produkter-fadoel.noerrebro-bryghus-dronning-louises-paaske-ale.jpg',
    imagePlural: "produkter-fadoel.noerrebro-fustage.png",
    // fadoelsType: 'Lys Ale',
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000085,
    expired: true,
    name: "Brdr. Adelhardt Øko Cola",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten december 2021</React.Fragment>
    ),
    expiredSize: "24x27,5cl",
    oldVareNr: "6050",
    keywords:
      "brødrene adelhart adelhard sodavand flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papbakke økologisk",
    imageSingle: "produkter-oelvand.6050-flaske.png",
    imagePlural: "produkter-oelvand.6050-kasse.png",
  },
  {
    varenr: 1000086,
    expired: true,
    name: "Frem Solbær Saft",
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten januar 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x25cl",
    oldVareNr: "1420",
    keywords:
      "frem sodavand fremsodavand ribe haribo harboe flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-solbaer-25cl.png",
    imagePlural: "produkter-oelvand.1350-kasse.png",
  },
  {
    varenr: 1000087,
    expired: true,
    name: "Skovlyst Hvedebryg",
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "15x50cl",
    oldVareNr: "8312",
    oelType: "Hvedeøl",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-hvedebryg-flaske.png",
    imagePlural: "produkter-oelvand.skovlyst-hvedebryg-kasse.png",
  },
  {
    varenr: 1000088,
    name: "Ramlösa",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>
        Ramlösa 20x33cl på glasflaske er helt udgået fra producenten og kommer
        ikke tilbage. Vi anbefaler ovenstående produkt Loka 20x33cl glas. Samme
        pris, kasse og flaske, samt vand fra en gammel svensk kilde.
      </React.Fragment>
    ),
    showExpired: true,
    expiredSize: "20x33cl",
    oldVareNr: "4050",
    keywords:
      "ramløse ramløsa ramlossa danskvand dansk vand apollinaris appollinaris  apolinaris appolinaris  flasker glasflasker 33cl 33 cl 20x33cl 20x33",
    imageSingle: "produkter-oelvand.ramlosa-33cl.png",
    imagePlural: "produkter-oelvand.4050-kasse.png",
  },
  {
    varenr: 1000089,
    expired: true,
    name: "Søbo Øko Mynte",
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment marts 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30x25cl",
    oldVareNr: "6150",
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård anton flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    inStockText: () => "Udsolgt fra tapperiet",
    imageSingle: "produkter-oelvand.6150-flaske.png",
    imagePlural: "produkter-oelvand.6150-kasse.png",
  },
  {
    varenr: 1000090,
    name: "Ramlösa Citrus",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>
        Ramlösa Citrus 20x33cl på glasflaske er helt udgået fra producenten og
        kommer ikke tilbage. Vi anbefaler ovenstående produkt Loka Citrus
        20x33cl glas. Samme pris, kasse og flaske, samt vand fra en gammel
        svensk kilde.
      </React.Fragment>
    ),
    showExpired: true,
    expiredSize: "20x33cl",
    oldVareNr: "4060",
    keywords:
      "ramløse ramløsa ramlossa danskvand dansk vand apollinaris appollinaris apolinaris appolinaris flasker glasflasker 33cl 33 cl 20x33cl 20x33",
    imageSingle: "produkter-oelvand.ramlosa-citrus-33cl.png",
    imagePlural: "produkter-oelvand.4060-kasse.png",
  },
  {
    varenr: 1000091,
    name: "Maribo Pilsner",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten maj 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30x33cl",
    oldVareNr: "1610",
    oelType: "Pilsner",
    keywords: "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.maribo-pilsner-flaske.png",
    imagePlural: "produkter-oelvand.maribo-pilsner-kasse.png",
  },
  {
    varenr: 1000092,
    name: "Parasol",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment december 2021</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "Ø: 180cm",
    oldVareNr: "2909",
    imageSingle: "pack4512s.parasol.png",
  },
  {
    varenr: 1000093,
    name: "Pilsner Urquell",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten maj 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x33cl",
    oldVareNr: "8640",
    oelType: "Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser",
    imageSingle: "produkter-oelvand.pilsner-urquell-33cl-flaske.png",
    imagePlural: "produkter-oelvand.pilsner-urquell-kasse.png",
  },
  {
    varenr: 1000095,
    name: "Anarkist Natholdet",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juni 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "25l",
    oldVareNr: "40805",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 25l 25 l liter classis classisk klassisk ipa anakist",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    // fadoelsType: 'India Pale Ale',
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000097,
    name: "Nørrebro Øko Gamle Københavner",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juni 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "20l",
    oldVareNr: "8065",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl ipa dansk danske danmark 20l 20 liter l bryghus økologisk økologiske classis classisk klassisk gammel københavner",
    // imageSingle: 'produkter-fadoel.noerrebro-bryghus-gamle-koebenhavner.jpg',
    imagePlural: "produkter-fadoel.noerrebro-fustage.png",
    // fadoelsType: 'Classic',
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 1000098,
    name: "Vitamin Well Refresh uden brus",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juli 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "12x50cl",
    oldVareNr: "9650",
    keywords:
      "kildevand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 vitaminwell",
    imageSingle: "produkter-oelvand.vitamin-well-refresh-flaske.png",
    imagePlural: "produkter-oelvand.vitamin-well-refresh-ramme.png",
    smag: "Lemonade/Kiwi",
  },
  {
    varenr: 1000099,
    name: "Nohrlund Øko | Summer Daiquiri",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juli 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "20l",
    oldVareNr: "24125",
    smag: "Rom, grape, jordbær og blodappelsin",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl type k keykeg polykeg polykegs keykegs dansk danske danmark 20l 20 liter l cocktails drinks økologisk økologiske norlund nordlund engangsfustager",
    imageSingle: "produkter-oelvand.nohrlund-summer-daiquiri.png",
    imagePlural: "produkter-fadoel.nohrlund-fustage.png",
    land: "Danmark",
    landKort: "DK",
    // fadoelsType: 'Cocktail',
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 10000100,
    name: "Grimbergen Blanche",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x33cl",
    oldVareNr: "8810",
    oelType: "Hvedeøl",
    keywords:
      "flaskeøl øl flasker glasflasker 33cl 33 cl 24x33cl 24x33 papkasser grimberger grinbergen grinberger grenberg grenberger grimbergem grindberg grimbregen",
    imageSingle: "produkter-oelvand.grimbergen-blance-flaske.png",
    imagePlural: "produkter-oelvand.grimbergen-blance-kasse.png",
    /*
    generalSearchPriority: 500,
    url: '/oel/flaskeoel/hvedeoel/grimbergen-blanche',
    categories: ['oelcideralkohol', 'flaskeoel', 'hvedeoel'],
    routeDef: {
      path: '/oel/flaskeoel/hvedeoel/grimbergen-blanche',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: 'Grimbergen Blanche',
      },
      meta: {
        title: 'Køb Grimbergen Blanche',
        description: 'Grimbergen Blanche',
      },
      props: {
        menuPicker: 'Alkohol',
        activeSidemenu: 'Flaskeoel',
      },
    },
    */
  },
  {
    varenr: 10000101,
    name: "Nikoline Appelsin",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30x25cl",
    oldVareNr: "4110",
    keywords: "appelsin sodavand flasker glasflasker 25cl 25 cl 30x25cl 30x25",
    imageSingle: "produkter-oelvand.nikoline-appelsin-flaske-25cl.png",
    imagePlural: "produkter-oelvand.4110-kasse.png",
    /*
    generalSearchPriority: 500,
    */
  },
  {
    varenr: 10000102,
    name: "Fuglsang Early Bird",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "20l",
    oldVareNr: "20595",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl lys ale fuglesang dansk danske danmark 20l 20 liter l",
    imageSingle: "produkter-fadoel.fuglsang-early-bird-glas.png",
    imagePlural: "produkter-fadoel.royal-uni-20l-2.png",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    /*
    // fadoelsType: 'Lys Ale',
    generalSearchPriority: 1000,
    url: '/fadoel/ale/lys-ale/fuglsang-early-bird',
    categories: ['fadoel', 'fadoelAle', 'fadoelLysAle', 'fadoelDansk'],
    routeDef: {
      path: '/fadoel/ale/lys-ale/fuglsang-early-bird',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelLysAleBC],
        title: 'Fuglsang Early Bird',
      },
      meta: {
        title: 'Køb Fuglsang Early Bird fadøl online | Lys ale med god smag',
        description: 'Fuglsang Early Bird Lys Ale Fadøl',
      },
      props: {
        menuPicker: 'Fadoel',
        activeSidemenu: 'Ale',
      },
    },
    */
  },
  {
    varenr: 10000103,
    name: "Brdr. Adelhardt Øko Citrus Danskvand",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x27,5cl",
    oldVareNr: "6020",
    // generalSearchPriority: 500,
    keywords:
      "brødrene adelhart adelhard dansk vand apollinaris med brus i appollinaris apolinaris appolinaris flasker glasflasker 27,5cl 27,5 cl 24x27,5cl 24x27,5 papbakke økologisk økologiske",
    imageSingle: "produkter-oelvand.6020-flaske.png",
  },
  {
    varenr: 10000104,
    name: "Skovlyst Nældebryg",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra vores sortiment november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "15x50cl",
    oldVareNr: "8222",
    oelType: "Porter",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-naeldebryg-flaske.png",
    imagePlural: "produkter-oelvand.skovlyst-naeldebryg-kasse.png",
    /*
    url: '/oel/flaskeoel/porter-stout/skovlyst-naeldebryg',
    categories: ['oelcideralkohol', 'flaskeoel', 'porter'],
    routeDef: {
      path: '/oel/flaskeoel/porter-stout/skovlyst-naeldebryg',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: 'Skovlyst Nældebryg',
      },
      meta: {
        title: 'Køb Skovlyst Nældebryg',
        description: 'Skovlyst Nældebryg',
      },
      props: {
        menuPicker: 'Alkohol',
        activeSidemenu: 'Flaskeoel',
      },
    },
    */
  },
  {
    varenr: 10000105,
    name: "Skovlyst IPA",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra vores sortiment november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "15x50cl",
    oldVareNr: "8232",
    oelType: "India Pale Ale",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-indian-pale-ale-flaske.png",
    imagePlural: "produkter-oelvand.skovlyst-indian-pale-ale-kasse.png",
    /*
    url: '/oel/flaskeoel/ipa/skovlyst-ipa',
    categories: ['oelcideralkohol', 'flaskeoel', 'ipa'],
    routeDef: {
      path: '/oel/flaskeoel/ipa/skovlyst-ipa',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: 'Skovlyst IPA',
      },
      meta: {
        title: 'Køb Skovlyst IPA',
        description: 'Skovlyst IPA',
      },
      props: {
        menuPicker: 'Alkohol',
        activeSidemenu: 'Flaskeoel',
      },
    },
    */
  },
  {
    varenr: 10000106,
    name: "Skovlyst Egebryg",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra vores sortiment november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "15x50cl",
    oldVareNr: "8242",
    oelType: "Classic",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-egebryg-flaske.png",
    imagePlural: "produkter-oelvand.8242-kasse.png",
    /*
    url: '/oel/flaskeoel/skovlyst-egebryg',
    categories: ['oelcideralkohol', 'flaskeoel', 'classic'],
    routeDef: {
      path: '/oel/flaskeoel/skovlyst-egebryg',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: 'Skovlyst Egebryg',
      },
      meta: {
        title: 'Køb Skovlyst Egebryg',
        description: 'Skovlyst Egebryg',
      },
      props: {
        menuPicker: 'Alkohol',
        activeSidemenu: 'Flaskeoel',
      },
    },
    */
  },
  {
    varenr: 10000108,
    name: "Skovlyst Birkebryg",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra vores sortiment november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "15x50cl",
    oldVareNr: "8282",
    oelType: "Pilsner",
    keywords:
      "flaskeøl øl flasker glasflasker 50cl 50 cl 15x50cl 15x50 papkasser",
    imageSingle: "produkter-oelvand.skovlyst-birkebryg-flaske.png",
    imagePlural: "produkter-oelvand.skovlyst-birkebryg-kasse.png",
    /*
    url: '/oel/flaskeoel/skovlyst-birkebryg',
    categories: ['oelcideralkohol', 'flaskeoel', 'pilsner'],
    routeDef: {
      path: '/oel/flaskeoel/skovlyst-birkebryg',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: 'Skovlyst Birkebryg',
      },
      meta: {
        title: 'Køb Skovlyst Birkebryg',
        description: 'Skovlyst Birkebryg',
      },
      props: {
        menuPicker: 'Alkohol',
        activeSidemenu: 'Flaskeoel',
      },
    },
    */
  },
  {
    varenr: 10000109,
    name: "Aqua d'or White Tea Hyldeblomst",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "12x50cl",
    oldVareNr: "21042",
    keywords:
      "lipton ice tea icetea nesstea icethe isthe iste flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50",
    imageSingle: "produkter-oelvand.21042-flaske.png",
    imagePlural: "produkter-oelvand.aquador-hyldeblomst-colli.png",
    /*
    generalSearchPriority: 500,
    categories: ['iceTea'],
    url: '/ice-tea/aqua-dor-white-tea-hyldeblomst',
    routeDef: {
      path: '/ice-tea/aqua-dor-white-tea-hyldeblomst',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, IceTeaBC],
        title: 'Aqua d\'or White Tea Hyldeblomst',
      },
      meta: {
        title: 'Aqua d\'or White Tea Hyldeblomst',
        description: '',
      },
      props: {
        menuPicker: 'VandSodavand',
        activeSidemenu: 'IceTea',
      },
    },
    */
  },
  {
    varenr: 10000110,
    name: "Wiibroe Årgangsøl",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten november 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30x33cl",
    oldVareNr: "2870",
    oelType: "Stærk Pilsner",
    keywords:
      "wibro wibroe flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.wiibroe-aargansoel-flaske.png",
    /*
    url: '/oel/flaskeoel/wiibroe-oaargangsoel',
    generalSearchPriority: 500,
    categories: ['oelcideralkohol', 'flaskeoel', 'staerkPilsner'],
    routeDef: {
      path: '/oel/flaskeoel/wiibroe-oaargangsoel',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: 'Wiibroe Årgangsøl',
      },
      meta: {
        title: 'Køb Wiibroe Årgangsøl',
        description: 'Wiibroe Årgangsøl',
      },
      props: {
        menuPicker: 'Alkohol',
        activeSidemenu: 'Flaskeoel',
      },
    },
    */
  },
  {
    varenr: 10000112,
    name: "Tuborg Øko Rå",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten efterår 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30l",
    oldVareNr: "8265",
    keywords: "flaskeøl øl flasker glasflasker 33cl 33 cl 30x33cl 30x33 kasse",
    imageSingle: "produkter-oelvand.tuborg-raa-flaske.png",
    imagePlural: "produkter-oelvand.tuborg-raa-kasse.png",
    /*
    oelType: 'Pilsner',
    generalSearchPriority: 500,
    url: '/oel/flaskeoel/tuborg-oeko-raa',
    categories: ['oelcideralkohol', 'flaskeoel', 'pilsner'],
    routeDef: {
      path: '/oel/flaskeoel/tuborg-oeko-raa',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, OelBC, FlaskeOelBC],
        title: 'Tuborg Øko Rå',
      },
      meta: {
        title: 'Køb Tuborg Øko Rå',
        description: 'Tuborg Øko Rå',
      },
      props: {
        menuPicker: 'Alkohol',
        activeSidemenu: 'Flaskeoel',
      },
    },
    */
  },
  {
    varenr: 10000113,
    name: "Coca-Cola Light",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten efterår 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x50cl",
    oldVareNr: "5362",
    keywords:
      "sukkerfri sukker fri 24 24stk stk flasker 50cl 50 cl 24x50cl 24x50 coca cola zero light sodavand cocacola cocacolalight",
    imageSingle: "produkter-oelvand.coca-cola-light-50cl-1.png",
    imagePlural: "produkter-oelvand.coca-cola-light-50cl-ramme-1.png",
    /*
    generalSearchPriority: 500,
    categories: ['cocaCola', 'sukkerfri'],
    url: '/sodavand/coca-cola/coca-cola-light-24x50cl',
    routeDef: {
      path: '/sodavand/coca-cola/coca-cola-light-24x50cl',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: 'Coca-Cola Light 24x50cl',
      },
      meta: {
        title: 'Coca-Cola Light 24x50cl',
        description: '',
      },
      props: {
        menuPicker: 'Sodavand',
        activeSidemenu: 'CocaCola',
      },
    },
    */
  },
  {
    varenr: 10000114,
    name: "Frem Æblemost",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten februar 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x25cl",
    oldVareNr: "1410",
    keywords:
      "frem sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-aeblemost-25cl.png",
    imagePlural: "produkter-oelvand.1410-kasse.png",
    /*
    generalSearchPriority: 500,
    categories: ['fremSodavand', 'saft'],
    url: '/sodavand/frem-sodavand/frem-aeblemost-24x25cl',
    routeDef: {
      path: '/sodavand/frem-sodavand/frem-aeblemost-24x25cl',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: 'Frem Æblemost 24x25cl',
      },
      meta: {
        title: 'Frem Æblemost 24x25cl',
        description: '',
      },
      props: {
        menuPicker: 'Sodavand',
        activeSidemenu: 'FremSodavand',
      },
    },
    */
  },
  {
    varenr: 10000116,
    name: "Nohrlund Øko | Summer Daiquiri",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten marts 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x18cl",
    oldVareNr: "24100",
    keywords: "drinks norlund nordlund",
    smag: "Rom, grape, jordbær og blodappelsin",
    imageSingle: "produkter-oelvand.nohrlund-oeko-daiquiri-flaske.png",
    imagePlural: "produkter-oelvand.nohrlund-kasse.png",
    imageSingleFirst: true,
    /*

    generalSearchPriority: 500,
    url: '/alkoholsodavand/nohrlund-oeko-summer-daiquiri',
    categories: ['oelcideralkohol', 'alkoholSodavand'],
    routeDef: {
      path: '/alkoholsodavand/nohrlund-oeko-summer-daiquiri',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, OelBC, AlkoholSodavandBC],
        title: 'Nohrlund Øko | Summer Daiquiri',
      },
      meta: {
        title: 'Køb Nohrlund Øko | Summer Daiquiri',
        description: 'Nohrlund Øko | Summer Daiquiri',
      },
      props: {
        menuPicker: 'Alkohol',
        activeSidemenu: 'AlkoholSodavand',
      },
    },
    */
  },
  {
    varenr: 10000117,
    name: "Tuborg Øko Rå",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl tuborg dansk danske danmark 25l 25 l liter økologisk økologiske ufiltreret ufilteret",
    imageSingle: "produkter-fadoel.tuborg-oeko-raa.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten juni 2022</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "25l",
    oldVareNr: "8405",
    /*
    generalSearchPriority: 1000,
    fadoelsType: 'Pilsner',
    land: 'Danmark',
    landKort: 'DK',
    gas: 'CO2',
    pressure: '2,5-3 bar',
    productTypeFadoel: true,
    invoiceDisplay: 'FustageItemRow',
    url: '/fadoel/pilsner/tuborg-oeko-raa',
    categories: ['fadoel', 'fadoelPilsner', 'fadoelDansk'],
    routeDef: {
      path: '/fadoel/pilsner/tuborg-oeko-raa',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: 'Tuborg Rå',
      },
      meta: {
        title: 'Køb Tuborg Rå fadøl - økologisk og ufiltreret',
        description: 'Tuborg Rå er en ufiltreret og økologisk fadøl af pilsnertypen. Under brygningen, på Carlsbergs svenske bryggeri i Falkenberg, benyttes økologiske råvarer.',
      },
      props: {
        menuPicker: 'Fadoel',
        activeSidemenu: 'Pilsner',
      },
    },
    productPageDescription: () => (
      <React.Fragment>
        <Heading3>Køb Tuborg Rå fadøl - økologisk og ufiltreret</Heading3>
        <Paragraph>
          Tuborg Rå er en ufiltreret og økologisk fadøl af pilsnertypen.
          Under brygningen, på Carlsbergs svenske bryggeri i Falkenberg, benyttes økologiske råvarer.
          Bryggeriet undlader at filtrere øllet, hvilket resulterer i flere smags- og duftindtryk.
        </Paragraph>
        <Paragraph>
          Tuborg Rå Øko fadøl blev testet på Northside- og Roskilde Festival i henholdsvis 2014 og 2015 og da 90% af dem der smagte på øllen kunne lide øllen, kan man vidst roligt sige at det er en succes.
        </Paragraph>
        <Paragraph>
          Øllet er en lidt uklart, da malt-, humle- og gærrester, ikke er filtreret fra.
        </Paragraph>
        <Heading3>Rul fustagen!</Heading3>
        <Paragraph>
          Som det også anvises på fustagen, skal man før brug, rulle fustagen for at få ingredienserne blandet ordenligt.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Fadøl udviklet til Northside og Roskilde festival. Ufiltreret og økologisk.<br /><br />Malt-, humle- og gærrester er ikke filtreret fra. Dette giver rig smag, men husk at rul tønden før brug.
      </React.Fragment>
    ),
    */
  },
  {
    varenr: 10000118,
    name: "Frem Grape Light",
    keywords:
      "sukkerfri sukker fri frem sodavand fremsodavand  ribe haribo harboe  flasker glasflasker 25cl 25 cl 24x25cl 24x25",
    imageSingle: "produkter-oelvand.frem-grape-light-25cl.png",
    imagePlural: "produkter-oelvand.1200-kasse.png",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten februar 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x25cl",
    oldVareNr: "1200",
    /*
    generalSearchPriority: 500,
    categories: ['fremSodavand', 'sukkerfri'],
    url: '/sodavand/frem-sodavand/frem-grape-light-24x25cl',
    routeDef: {
      path: '/sodavand/frem-sodavand/frem-grape-light-24x25cl',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandFremBC],
        title: 'Frem Grape Light 24x25cl',
      },
      meta: {
        title: 'Frem Grape Light 24x25cl',
        description: '',
      },
      props: {
        menuPicker: 'Sodavand',
        activeSidemenu: 'FremSodavand',
      },
    },
    */
  },
  {
    varenr: 10000121,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment juli 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30x25cl",
    oldVareNr: "6000",
    name: "Søbo Øko Blandet Kasse",
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård anton flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske",
    imagePlural: "produkter-oelvand.6000-kasse.png",
    usePluralImage: true,
    popupDescription: () => (
      <React.Fragment>
        <Paragraph>
          Vi laver en blandet kasse med hyldeblomst, æblemost, solbær og
          hindbær.
        </Paragraph>
        <Paragraph>
          Hvis I ønsker selv at blande en kasse, kan dette gøres ved at trykke
          på &quot;Bland selv&quot; og vælge antal, for de varer I ønsker at
          blande.
        </Paragraph>
      </React.Fragment>
    ),
    smallDescription: () => (
      <React.Fragment>
        <Span block fontSize="13px" paddingTop="5px">
          Vi kan blande hyldeblomst, æblemost, solbær og hindbær.
        </Span>
      </React.Fragment>
    ),
    description: () => (
      <Span fontSize="13px">
        Vi kan blande hyldeblomst, æblemost, solbær og hindbær.
      </Span>
    ),
    /*
    generalSearchPriority: 500,
    categories: ['soebogaard'],
    url: '/soebogaard/soebogaard-oeko-blandet-kasse',
    routeDef: {
      path: '/soebogaard/soebogaard-oeko-blandet-kasse',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: 'Søbogaard Øko Blandet Kasse',
      },
      meta: {
        title: 'Søbogaard Øko Blandet Kasse',
        description: '',
      },
      props: {
        menuPicker: 'VandSodavand',
        activeSidemenu: 'Soebogaard',
      },
    },
    */
  },
  {
    varenr: 10000124,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra leverandør august 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30l",
    oldVareNr: "8645",
    name: "Pilsner Urquell",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 30l 30 l liter tjekkiet",
    imageSingle: "produkter-fadoel.pilsner-urquell.png",
    imagePlural: "produkter-fadoel.urquell-fustage.png",
    fadoelsType: "Pilsner",
    land: "Tjekkiet",
    landKort: "CZ",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    /*
    generalSearchPriority: 1000,
    related: [4915, 23615, 8415, 8776],
    url: '/fadoel/pilsner/pilsner-urquell',
    categories: ['fadoel', 'fadoelPilsner', 'fadoelUdenlandsk'],
    routeDef: {
      path: '/fadoel/pilsner/pilsner-urquell',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelPilsnerBC],
        title: 'Pilsner Urquell',
      },
      meta: {
        title: 'Pilsner Urquell fadøl - Den ægte oprindelige pilsner fra Tjekkiet',
        description: 'Pilsner Urquell er verdens første gyldne pilsner og har dannet grundstenen for pilsnere verden over.',
      },
      props: {
        menuPicker: 'Fadoel',
        activeSidemenu: 'Pilsner',
      },
    },
    */
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Den ægte oprindelige pilsner</Heading3>
        <Paragraph>
          Pilsner Urquell er verdens første gyldne pilsner og blev brygget
          første gang den 5. oktober 1842 i byen Plzen, Tjekkiet. Den gode
          første tår fylder munden med en frisk smag af humle og man oplever som
          det næste en let maltet sødme med toner af karamel. Det er denne gode
          smag der er grunden til at Pilsner Urquell stadig bliver brygget på
          præcis samme opskrift som i 1842. Den er bestemt værd at prøve som
          fadøl til jeres arrangement!
        </Paragraph>
        <Paragraph fontSize="12px">* Hvis lager haves</Paragraph>
      </React.Fragment>
    ),
  },
  {
    varenr: 10000125,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten januar 2020</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "25l",
    oldVareNr: "10000125",
    name: "Tuborg Guld",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl 25l 25 l liter tuborg gyldne dame damer gyldnedamer dansk danske danmark tuborgguld guldtuborg",
    imageSingle: "produkter-fadoel.tuborg-guld.png",
    imagePlural: "produkter-fadoel.carlsberg-fustage.png",
    fadoelsType: "Stærk Pilsner",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
  },
  {
    varenr: 10000126,
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30l",
    oldVareNr: "8265",
    name: "Skovlyst Bøgebryg",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl dansk danske danmark 30l 30 l liter",
    imageSingle: "produkter-fadoel.skovlyst-boegebryg.png",
    imagePlural: "produkter-fadoel.skovlyst-fustage-20-liter.png",
    fadoelsType: "Brown Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    productPageDescriptionLong: () => (
      <React.Fragment>
        <Heading3>Skovlyst Bøgebryg - mørk og kraftfuld</Heading3>
        <Paragraph>
          Skovlyst BøgeBryg er en mørk og kraftfuld ale. Øllet får en fadlageret
          og træagtig aroma fra de ca. tre uger som øllet lagrer sammen med
          bøgetræsekstrakt. Bøgebryg får endvidere en herlig aroma af citrus fra
          cascade humlen, som er med til at give øllet en god bitterhed.
        </Paragraph>
      </React.Fragment>
    ),
    popupDescription: () => (
      <React.Fragment>
        Mørk og kraftfuld ale. Denne øl er lagrer ca. tre uger, sammen med
        bøgetræsekstrakt.
        <br />
        <br />
        Denne fadøl har en god bitterhed.
      </React.Fragment>
    ),
    /*
    url: '/fadoel/ale/brown-ale/skovlyst-boegebryg',
    categories: ['fadoel', 'fadoelAle', 'fadoelBrownAle', 'fadoelDansk'],
    routeDef: {
      path: '/fadoel/ale/brown-ale/skovlyst-boegebryg',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelAleBC, FadoelBrownAleBC],
        title: 'Skovlyst Bøgebryg',
      },
      meta: {
        title: 'Skovlyst Bøgebryg - mørk og kraftfuld',
        description: 'Skovlyst Bøgebryg har en fadlageret og træagtig aroma fra de ca. tre uger som øllet lagrer sammen med bøgetræsekstrakt.',
      },
      props: {
        menuPicker: 'Fadoel',
        activeSidemenu: 'Ale',
      },
    },
    */
  },
  {
    varenr: 10000127,
    name: "Søbo Øko Cola Brus",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten august 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "30x25cl",
    oldVareNr: "6270",
    keywords:
      "søbogaard soebogaard saft sobogaard søbogård soebogård såbygaard søborgaard søbygaard sobogård Søborggård soeborggård anton flasker glasflasker 25cl 25 cl 30x25cl 30x25 plastkasser økologisk økologiske øcola",
    imageSingle: "produkter-oelvand.6270-flaske.png",
    imagePlural: "produkter-oelvand.soebogaard-ocola-ramme.png",
    /*
    generalSearchPriority: 500,
    categories: ['soebogaard'],
    url: '/soebogaard/soebogaard-oeko-cola-brus',
    routeDef: {
      path: '/soebogaard/soebogaard-oeko-cola-brus',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, SoebogaardBC],
        title: 'Søbogaard Øko Cola Brus',
      },
      meta: {
        title: 'Søbogaard Øko Cola Brus',
        description: '',
      },
      props: {
        menuPicker: 'VandSodavand',
        activeSidemenu: 'Soebogaard',
      },
    },
    */
  },
  {
    varenr: 10000128,
    name: "Svaneke Øko Don't Worry Alkoholfri",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra sortiment august 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "20l",
    oldVareNr: "19425",
    keywords:
      "fustage fustager fustace fus fad fadølsfustage fadøl letøl alkoholfri frokostøl alkoholdfri alkoholfrit let light ølight øllight light øl 20l 20 l liter",
    imageSingle: "produkter-fadoel.svaneke-pilsner.png",
    imagePlural: "produkter-fadoel.svaneke-fustage.png",
    fadoelsType: "Lys Ale",
    land: "Danmark",
    landKort: "DK",
    gas: "CO2",
    pressure: "2,5-3 bar",
    productTypeFadoel: true,
    invoiceDisplay: "FustageItemRow",
    /*
    generalSearchPriority: 1000,
    url: '/fadoel/letoel/svaneke-dont-worry-alkoholfri',
    categories: ['fadoel', 'fadoelLetoel', 'fadoelUdenlandsk'],
    routeDef: {
      path: '/fadoel/letoel/svaneke-dont-worry-alkoholfri',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, FadoelBC, FadoelLetoelBC],
        title: 'Svaneke Don\'t Worry Alkoholfri',
      },
      meta: {
        title: 'Køb Svaneke Don\'t Worry Alkoholfri fadøl online. En dansk fadøl uden alkohol.',
        description: 'Svaneke Don\'t Worry Alkoholfri fadøl - Fås ikke bedre i Danmark.',
      },
      props: {
        menuPicker: 'Fadoel',
        activeSidemenu: 'Letoel',
      },
    },
    */
  },
  {
    varenr: 10000129,
    name: "Sprite Dåse",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten september 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "24x33cl",
    oldVareNr: "5313",
    keywords: "aluminium alu dåser 33cl 33 cl 24x33cl 24x33",
    imageSingle: "produkter-oelvand.5313-flaske.png",
    imagePlural: "produkter-oelvand.5313-kasse.png",
  },
  {
    varenr: 10000130,
    name: "Nestea Peach",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten september 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "12x50cl",
    oldVareNr: "9332",
    keywords:
      "lipton ice tea icetea nesstea icethe isthe iste flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50",
    imageSingle: "produkter-oelvand.nestea-peach-flaske.png",
    imagePlural: "produkter-oelvand.nestea-peach-ramme.png",
    /*
    generalSearchPriority: 500,
    categories: ['iceTea'],
    url: '/ice-tea/nestea-peach',
    routeDef: {
      path: '/ice-tea/nestea-peach',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, IceTeaBC],
        title: 'Nestea Peach',
      },
      meta: {
        title: 'Nestea Peach',
        description: '',
      },
      props: {
        menuPicker: 'VandSodavand',
        activeSidemenu: 'IceTea',
      },
    },
    */
  },
  {
    varenr: 10000131,
    name: "Aqua d'or Tropical Ice Tea Zero",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten september 2023</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "12x50cl",
    oldVareNr: "21062",
    keywords:
      "aquador aqua aqua'd'or aquad'or door dor auqa dór kildevand vand flasker plastflasker plastikflasker 50cl 50 cl 12x50cl 12x50 smag",
    imageSingle: "produkter-oelvand.aquador-tropical-ice-tea.png",
    imagePlural: "produkter-oelvand.aquador-tropical-ice-tea-ramme.png",
    /*
    generalSearchPriority: 500,
    categories: ['iceTea'],
    url: '/kildevand/aquador/aqua-dor-tropical-ice-tea-zero',
    routeDef: {
      path: '/kildevand/aquador/aqua-dor-tropical-ice-tea-zero',
      component: loadable(() => import('../../../pages/produktsider/General')),
      breadcrumbs: {
        base: [HomeBC, KildevandBC, KildevandAquadorBC],
        title: 'Aqua d\'or Tropical Ice Tea Zero',
      },
      meta: {
        title: 'Aqua d\'or Tropical Ice Tea Zero',
        description: '',
      },
      props: {
        menuPicker: 'Kildevand',
        activeSidemenu: 'Aquador',
      },
    },
    */
  },
  {
    varenr: 10000132,
    name: "Coca-Cola Light",
    expired: true,
    expiredMessage: () => (
      <React.Fragment>Udgået fra producenten</React.Fragment>
    ),
    showExpired: true,
    expiredSize: "8x150cl",
    oldVareNr: "5361",
    keywords:
      "sukkerfri sukker fri 8 8stk stk flasker 150cl 150 cl 8x150cl 8x150 coca cola zero light sodavand",
    imageSingle: "produkter-oelvand.coca-cola-light-150cl-1.png",
    imagePlural: "produkter-oelvand.coca-cola-light-150cl-ramme-1.png",
    /*
    generalSearchPriority: 450,
    categories: ["cocaCola", "sukkerfri"],
    url: "/sodavand/coca-cola/coca-cola-light-8x150cl",
    routeDef: {
      path: "/sodavand/coca-cola/coca-cola-light-8x150cl",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC, SodavandCocaColaBC],
        title: "Coca-Cola Light 8x150cl",
      },
      meta: {
        title: "Coca-Cola Light 8x150cl",
        description: "",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "CocaCola",
      },
    },
    */
  },
  {
    varenr: 10000133,
    name: "Fritz Kola",
    keywords:
      "cola hamburg hamborg flasker glasflasker glas 33cl 33 cl 24x33cl 24x33",
    imageSingle: "produkter-oelvand.fritz-kola-33cl.png",
    imagePlural: "produkter-oelvand.fritz-kola-33cl-kasse.png",
    expired: true,
    expiredMessage: () => <React.Fragment>Udgået fra sortiment</React.Fragment>,
    showExpired: true,
    expiredSize: "8x150cl",
    oldVareNr: "34350",
    /*
    url: "/sodavand/fritz-kola",
    generalSearchPriority: 450,
    categories: ["fritz"],
    routeDef: {
      path: "/sodavand/fritz-kola",
      component: loadable(() => import("../../../pages/produktsider/General")),
      breadcrumbs: {
        base: [HomeBC, SodavandBC],
        title: "Fritz Kola 24x33cl",
      },
      meta: {
        title: "Fritz Kola 24x33cl",
        description:
          "En af verdens mest energiske colaer (25 mg koffein/100 ml). Den indeholder æfte cola-nød ekstrakt og naturligt koffein - en uforvekslelig opkvikker.",
      },
      props: {
        menuPicker: "Sodavand",
        activeSidemenu: "FritzKola",
      },
    },
    productPageDescription: () => (
      <Paragraph>
        Fritz Kola - kultcolaen fra Hamborg. En af verdens mest energiske colaer
        (25 mg koffein/100 ml). Den indeholder ægte colanød-ekstrakt og
        naturligt koffein - en uforvekslelig opkvikker.
      </Paragraph>
    ),
    popupDescription: () => (
      <Paragraph>
        Fritz Kola - kultcolaen fra Hamborg. En af verdens mest energiske colaer
        (25 mg koffein/100 ml). Den indeholder ægte colanød-ekstrakt og
        naturligt koffein - en uforvekslelig opkvikker.
      </Paragraph>
    ),
    */
  },
];
